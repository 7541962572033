import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class GuestService {
    constructor(private http: HttpClient) {}

    getGuestproviderDetails(token: string, name: string, role: string, reselectProvider: any, preferredName: string): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/guest/providerDetails';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
                'name': name,
                'role': role,
                'reselectProvider': reselectProvider,
                'preferredName': preferredName
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    createGuestProvider(token: string, provider: string, signUpInfo: any, name: string, role: string, reselectProvider: boolean, oldPreferredUserName: string, preferredName: string): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/guest/providerDetails';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
                'name': name,
                'role': role,
                'preferredName': preferredName,
                'oldPreferredName': oldPreferredUserName
            }),
        };
        const body = { 'provider': provider, 'signUpInfo': signUpInfo, 'reselectProvider': reselectProvider };
        //based on SHPLT-7895 ticket we are deleting emil from the payload because in API side we are using email from the token not from the payload.
        if (body.signUpInfo && body.signUpInfo.email) {
            delete body.signUpInfo.email;
        }
        return this.http.post<any>(URI, body, httpOptions);
    }
}
