import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class CustomAdminService {

  constructor(private http: HttpClient) { }

  private createHttpOptions(token: string, name: string, role: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token,
        'name': name,
        'role': role
      }),
    };
  }

  private getBaseURI(): string {
    return environment.rmpEndPointUser.url + '/custom/customProviderDetails';
  }

  getCustomAdminproviderDetails(token: string, name: string, role: string): Observable<any> {
    const URI = this.getBaseURI();
    const httpOptions = this.createHttpOptions(token, name, role);
    return this.http.get<any>(URI, httpOptions);
  }

  createCustomAdminproviderDetails(token: string, provider: string, signUpInfo: any, name: string, role: string): Observable<any> {
    const URI = this.getBaseURI();
    const httpOptions = this.createHttpOptions(token, name, role);
    return this.http.post<any>(URI, signUpInfo, httpOptions);
  }
}