import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';
import { SettingsPinReplacementComponent } from '../settings-pin-replacement/settings-pin-replacement.component';
import { GuestPrintAdminApprovalComponent } from '../admin-approval/guestprint-admin-approval.component';

import { SubDialogInfo } from '../../../scp-shared/interfaces/dialog';
import { DialogService } from '../../../scp-shared/services/dialog.service';
import { WarningActionDialogComponent } from '../../../scp-shared/dialogs/warning-action-dialog/warning-action-dialog.component';

@Component({
  selector: 'app-settings-login',
  templateUrl: './settings-login.component.html',
  styleUrls: ['./settings-login.component.scss'],
})
export class SettingsLoginComponent extends BaseComponent implements OnInit {
  settingsFormGroup: FormGroup;
  enablePinFormCtrl: FormControl;
  defaultLoginFormCtrl: FormControl;
  enablePinAutoGenerateFormCtrl: FormControl;
  pinMinDigitFormCtrl: FormControl;
  pinMaxAttemptsFormCtrl: FormControl;
  enableCardFormCtrl: FormControl;
  cardMaxAttemptsFormCtrl: FormControl;
  enable2FAFormCtrl: FormControl;
  enableGuestPrintFormCtrl: FormControl;

  guestPrintSettingsFormGroup: FormGroup;
  guestPrintEmailFormCtrl: FormControl;
  guestPrintExpiryDateFormCtrl: FormControl;
  guestPrintUserValidityFormCtrl: FormControl;

  guestPrintSubscriptionId: string;
  isGuestPrintConnected = false;
  guestPrintConnectionStatus: string;

  hasError = false;
  isAdminUser = false;

  isGoogle: boolean;

  adminApprovalDialogRef: MatDialogRef<GuestPrintAdminApprovalComponent>;
  guestPrintScopes: string = 'Mail.Read';

  constructor(private logHandlerService: LogHandlerService, private dialogService: DialogService) {
    super();

    this.defaultLoginFormCtrl = new FormControl('1', [Validators.required]);
    this.enablePinFormCtrl = new FormControl(null, [Validators.required]);
    this.enablePinAutoGenerateFormCtrl = new FormControl(null, [Validators.required]);
    this.pinMinDigitFormCtrl = new FormControl(null, [Validators.required, Validators.min(4), Validators.max(6)]);
    this.pinMaxAttemptsFormCtrl = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(10)]);
    this.enableCardFormCtrl = new FormControl(null, [Validators.required]);
    this.cardMaxAttemptsFormCtrl = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(10)]);
    this.enable2FAFormCtrl = new FormControl(null, [Validators.required]);
    this.enableGuestPrintFormCtrl = new FormControl(false, [Validators.required]);

    this.guestPrintEmailFormCtrl = new FormControl('');
    this.guestPrintExpiryDateFormCtrl = new FormControl('');
    this.guestPrintUserValidityFormCtrl = new FormControl(null, [Validators.required, Validators.min(168), Validators.max(648)]);

    this.settingsFormGroup = new FormGroup({
      default_login_method: this.defaultLoginFormCtrl,
      enable_pin: this.enablePinFormCtrl,
      enable_pin_auto_generate: this.enablePinAutoGenerateFormCtrl,
      pin_min_digit: this.pinMinDigitFormCtrl,
      pin_max_attempts: this.pinMaxAttemptsFormCtrl,
      enable_card: this.enableCardFormCtrl,
      card_max_attempts: this.cardMaxAttemptsFormCtrl,
      enable_2FA: this.enable2FAFormCtrl,
      enable_guest_print: this.enableGuestPrintFormCtrl,
      guest_print_user_validity: this.guestPrintUserValidityFormCtrl,
    });

    this.guestPrintSettingsFormGroup = new FormGroup({
      guest_print_email: this.guestPrintEmailFormCtrl,
      guest_print_expiry_date: this.guestPrintExpiryDateFormCtrl,
    });

    this.guestPrintSubscriptionId = null;
    this.guestPrintConnectionStatus = 'offline';

    this.isGoogle = this.appService.isGoogleWorkspace();
  }

  ngOnInit() {
    this.isAdminUser = this.appService.isSCPAdminUser;

    if (this.isAdminUser) {
      this.getSettingsData();
      this.getGraphSubscriptionSettingsData();
    }
  }

  changeDefaultLogin() {
    if (this.defaultLoginFormCtrl.value == 2) {
      this.enablePinFormCtrl.setValue(true); // Enable Pin Login
    } else if (this.defaultLoginFormCtrl.value == 3) {
      this.enableCardFormCtrl.setValue(true); // Enable Card Login
    }
  }

  update2faSetting() {
    if ((!this.enablePinFormCtrl.value) && this.enableGuestPrintFormCtrl.value) {
        this.showPinLoginDisableWarningDialog(this.translate.instant('scp.settings.login.pin.heading'), this.translate.instant('scp.settings.login.pin.disable_pin_login_error_message'));
        this.enablePinFormCtrl.setValue(true);
        return;
    }    
    if (!(this.enablePinFormCtrl.value && this.enableCardFormCtrl.value)) {
      this.enable2FAFormCtrl.setValue(false);
    }
    if (!this.enablePinFormCtrl.value) {
      this.enablePinAutoGenerateFormCtrl.setValue(false);
    }
  }

  showPinLoginDisableWarningDialog(title: string, action : string) {
        this.dialog.open(WarningActionDialogComponent, {
              width: '35%',
              minHeight: '20%',
              data: {
                title: title,
                message: action,
              },
            });
  }

  updatePinAutoGenerateSetting() {
    if (this.enablePinAutoGenerateFormCtrl.value) {
      this.enablePinFormCtrl.setValue(true);
    }
  }

  updatePinCardSetting() {
    this.enablePinFormCtrl.setValue(true);
    this.enablePinAutoGenerateFormCtrl.setValue(true);
    this.enableCardFormCtrl.setValue(true);
  }

  updatePinAutoGenerate() {
    if (this.enablePinAutoGenerateFormCtrl.value) {
      this.enablePinFormCtrl.setValue(true);
    }
  }

  saveSettings() {
    if (this.settingsFormGroup.valid) {
      this.appService
        .updateSettings({
          ...this.settingsFormGroup.value,
          pin_max_digit: this.pinMinDigitFormCtrl.value,
        })
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.getSettingsData();
            this.appService.showSuccess(this.translate.instant('scp.settings.save_success_message'));
          },
          () => {
            this.logHandlerService.addSystemLogCustom('Settings data failed to save (ST00021)', 'Security', 'save setting', 'SCP-Portal-ST00021');
            this.appService.showError(this.translate.instant('scp.settings.save_error_message'));
          }
        );
    }
  }

  showPinReplacementDialog() {
    this.dialog.open(SettingsPinReplacementComponent, {
      width: '500px',
      disableClose: true,
    });
  }

  getSettingsData() {
    this.appService
      .getSettings()
      .pipe(this.takeUntilDestroyed<[]>())
      .subscribe(
        (data) => {
          (data || []).forEach(({ key, value, type }: { key: string; value: string; type: string }) => {
            const ctrl = this.settingsFormGroup.get(key);

            if (ctrl) {
              if (type === 'bool') {
                ctrl.setValue(value === 'true'); // Convert to javascript boolean
              } else {
                ctrl.setValue(value);
              }
            }
          });
        },
        () => {
          this.hasError = true;
        }
      );
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  isEmailInAllowedDomains(email: string, allowedDomains: string[]): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+)$/;
    const match = email.match(emailRegex);

    if (!match) {
      return false;
    }

    const domain = match[1];
    return allowedDomains.includes(domain);
  }

  updateGuestPrintSetting() {
    if (this.enableGuestPrintFormCtrl.value) {
      this.appService
        .getGuestPrintAppPermission()
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          (data) => {
            if (!data['app_permission']?.includes(this.guestPrintScopes)) {
              this.openAdminApprovalDialog(data['app_client_id']);
            }
            this.enablePinFormCtrl.setValue(true);
          },
          () => {
            this.appService.showError(this.translate.instant('scp.settings.login.guestprint.check_permission_error_message'));
          }
        );
    } else {
      if (this.guestPrintSubscriptionId !== null) {
        this.switchOffGuestPrintDialog();
      }
    }
  }

  openAdminApprovalDialog(appId: string) {
    this.adminApprovalDialogRef = this.dialog.open(GuestPrintAdminApprovalComponent, {
      width: '40%',
      minHeight: '20%',
      disableClose: true,
      data: { appClientId: appId },
    });

    this.adminApprovalDialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.enableGuestPrintFormCtrl.setValue(!this.enableGuestPrintFormCtrl.value);
      }
    });
  }

  switchOffGuestPrintDialog() {
    return new Promise((resolve, reject) => {
      let hasError = false;
      let errorMessage = '';

      const subDialogInfo: SubDialogInfo = {
        title: this.translate.instant('scp.settings.login.guestprint.heading'),
        mainMessage: this.translate.instant('scp.settings.login.guestprint.switch_off_message'),
        cancelText: this.translate.instant('scp.common.cancel_button_text'),
        confirmText: this.translate.instant('scp.common.ok_button_text'),
        infoMode: false,
        placeHolderVariables: this.translate.instant('scp.settings.login.guestprint.switch_off_message'),
      };
      this.dialogService.open(subDialogInfo);
      this.dialogService.confirmed().subscribe((result) => {
        //Indicates Action Confirmed
        if (result) {
          this.appService
            .getGuestPrintAppPermission()
            .pipe(this.takeUntilDestroyed())
            .subscribe(
              (data) => {
                if (data['app_permission']?.includes(this.guestPrintScopes)) {
                  this.deleteGraphSubscription();
                  this.saveSettings();
                } else {
                  this.openAdminApprovalDialog(data['app_client_id']);
                }
              },
              () => {
                this.appService.showError(this.translate.instant('scp.settings.login.guestprint.check_permission_error_message'));
              }
            );
        } else {
          this.enableGuestPrintFormCtrl.setValue(true);
        }
      });
    });
  }

  createGraphSubscription() {
    if (this.isValidEmail(this.guestPrintEmailFormCtrl.value)) {
      if (this.isEmailInAllowedDomains(this.guestPrintEmailFormCtrl.value, this.appService.supportedDomains)) {
        this.appService
          .getGuestPrintAppPermission()
          .pipe(this.takeUntilDestroyed())
          .subscribe(
            (data) => {
              if (data['app_permission']?.includes(this.guestPrintScopes)) {
                this.appService
                  .createGraphSubscription({
                    email: this.guestPrintEmailFormCtrl.value,
                  })
                  .pipe(this.takeUntilDestroyed())
                  .subscribe(
                    () => {
                      this.getGraphSubscriptionSettingsData();
                      this.saveSettings();
                    },
                    () => {
                      this.appService.showError(this.translate.instant('scp.settings.login.guestprint.create_error_message'));
                    }
                  );
              } else {
                this.openAdminApprovalDialog(data['app_client_id']);
              }
            },
            () => {
              this.appService.showError(this.translate.instant('scp.settings.login.guestprint.check_permission_error_message'));
            }
          );
      } else {
        this.appService.showError(this.translate.instant('scp.settings.login.guestprint.email_domain_error_message'));
      }
    } else {
      this.appService.showError(this.translate.instant('scp.settings.login.guestprint.email_error_message'));
    }
  }

  deleteGraphSubscription() {
    this.appService
      .deleteGraphSubscription(this.guestPrintSubscriptionId)
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        () => {
          this.getGraphSubscriptionSettingsData();
        },
        () => {
          this.appService.showError(this.translate.instant('scp.settings.login.guestprint.delete_error_message'));
        }
      );
  }

  getGraphSubscriptionSettingsData() {
    this.guestPrintEmailFormCtrl.setValue('');
    this.guestPrintExpiryDateFormCtrl.setValue('');
    this.guestPrintSubscriptionId = null;
    this.isGuestPrintConnected = false;
    this.guestPrintConnectionStatus = 'offline';

    let formattedExpiryDate = '-';

    this.appService
      .getGraphSubscriptionInSCP()
      .pipe(this.takeUntilDestroyed<[]>())
      .subscribe(
        (data) => {
          if (data.length !== 0) {
            const firstKey = Object.keys(data)[0];
            this.guestPrintEmailFormCtrl.setValue(data[firstKey]['email']);
            this.guestPrintSubscriptionId = data[firstKey]['subscriptionId'];

            if (data[firstKey]['dateExpires']) {
              if (moment(data[firstKey]['dateExpires'] + 'Z').isValid()) {
                formattedExpiryDate = moment(data[firstKey]['dateExpires'] + 'Z')
                  .local()
                  .locale(navigator.language)
                  .format('L LTS');
              } else {
                formattedExpiryDate = moment(data[firstKey]['dateExpires']).local().locale(navigator.language).format('L LTS');
              }
            }
            this.guestPrintExpiryDateFormCtrl.setValue(formattedExpiryDate);

            if (this.guestPrintSubscriptionId === null) {
              this.isGuestPrintConnected = false;
              this.guestPrintConnectionStatus = 'offline';
            } else {
              this.isGuestPrintConnected = true;
              if (moment(data[firstKey]['dateExpires'], 'YYYY-MM-DDTHH:mm:ss').utc().toISOString() >= moment().toDate().toISOString()) {
                this.guestPrintConnectionStatus = 'online';
              } else {
                this.guestPrintConnectionStatus = 'error';
              }
            }
          }
        },
        () => {
          this.hasError = true;
        }
      );
  }
}
