import { Injectable } from '@angular/core';
import { IComponentStates, IRmmComponentStates } from '../interfaces/componentStates';
@Injectable({
    providedIn: 'root',
})

export class ViewManagementService {

    adminRole: string;
    rmmAdminRole: string;

    rmmComponentStates: IRmmComponentStates = {
        rmmMultiTenantDashboard: {
            view: true
        },
        rmmDashboard: {
            view: true
        },
        devices: {
            view: true
        },
        displays: {
            view: true
        },
        MFPPrinters: {
            view: true
        },
        Security: {
            view: true
        },
        rmmSecurityControl: {
            view: true
        },
        rmmCreatePolicy: {
            view: true
        },
        CreateSchedule: {
            view: true
        },
        Settings: {
            view: true
        },
        AdminUsers: {
            view: true,
            addUser: true,
            addGuest: true,
            deleteUser: true,
            updateUser: true
        },
        SupportedDomains: {
            view: true,
            refreshDomainList: true,
            editDomains: true,
        },
        GroupDevice: {
            view: true
        },
        Agent: {
            view: true
        },
        rmmEmailAlert: {
            view: true
        },
        rmmDeviceCloning: {
            view: true
        },
        storageBackup: {
            view: true
        },
        AdminLog: {
            view: true
        },
        OperationLog: {
            view: true
        },
        DeviceLog: {
            view: true
        },
        rmmDeviceType: {
            view: true
        },
        printerDriver: {
            view: true
        },
        fleetReport: {
            view: true
        },
        usageReport: {
            view: true
        },
        securityReport: {
            view: true
        },
        analytics: {
            view: true
        },
        System: {
            view: true,
        },
        TechnicalService: {
            view: true,
        },
        About: {
            view: true
        },
        CounterVariation: {
            view: true
        },
        connectWise:{
            view: true
        },
        rmmCeoJuice: {
            view: true
        },
        AgentDownloads: {
            view: true
        },
        ServiceReport: {
            view: true
        },
        alerts: {
            view: true
        },
        GetData: {
            view: true
        },
        RemoteMaintenance: {
            view: true
        },
        FirmwareUpdate: {
            view: true
        },
        Tenant: {
            view: true
        },
        rmmGROP:{
            view: true
        },
        FirmwareUpdate2: {
            view: true
        },
        rmmCustomDeviceType: {
            view: true
        },
        rmmTasks: {
            view: true
        },
        rmmdevicesfirmwareupdate: {
            view: true
        },
        rmmagentlog: {
            view: true
        },
        rmmdevicesfirmwareupdateeu: {
            view: true
        },
        rmmevatic: {
            view: true
        },
        rmmaddressbook:{
            view: true
        }
    };

    componentStates: IComponentStates = {
        MobileUsers: {
            view: true,
            addUserImport: true,
            addUserGroup: true,
            addUserImportCSV: true,
            removeUser: true,
            licenseConfig: true,
            addImportIdCardNumber: true,
            idCardColumn: true,
        },
        Workspaces: {
            view: true,
            addWorkspaceManual: true,
            addWorkSpaceImport: true,
            addWorkspaceWithGroup: true,
            addWorkspaceImportCSV: true,
            removeWorkspace: true,
            editWorkspace: true,
            registerDevice: true,
            addMFP: true,
            removeMFP: true,
            addDisplay: true,
            removeDisplay: true,
            editDisplay: true,
            configureDisplay: true,
            editDevice: true,
            voiceConfigure: true,
            deleteDevice: true,
            licenseConfig: true,
        },
        DeviceAndAgents: {
            view: true,
            editSettings: true,
            editDisplay: true,
            editCasting: true,
            configureDisplay: true,
            reDiscover: true,
        },
        AgentsAndDeviceSummary: {
            view: true,
            edit: true,
        },
        Agents: {
            view: true,
        },
        AdminSettings: {
            view: true,
        },
        Settings: {
            view: true,
        },
        AdminUsers: {
            view: true,
            addAdmin: true,
            assignRole: true,
            removeAdmin: true,
        },
        SupportedDomains: {
            view: true,
            refreshDomainList: true,
            editDomains: true,
        },
        NotificationPreferences: {
            view: true,
            edit: true,
        },
        Alerts: {
            view: true,
            edit: true,
        },

        Subscriptions: {
            view: true,
        },
        Analytics: {
            view: true,
            exportCSV: true,
        },
        Downloads: {
            view: true,
            mfpAgent: true,
            SNMPConfigurationLink: true,
            displayAgent: true,
            commonInstaller: true,
            commonInstallerWithConfig: true,
            outlookConfiguration:true,
        },
        System: {
            view: true,
        },
        TechnicalService: {
            view: true,
        },
        SystemLog: {
            view: true,
            exportCSV: true,
        },
        AdminLog: {
            view: true,
            exportCSV: true,
        },
        AgentUpdates: {
            view: true,
            updateAgent: true,
            updatePolicy: true,
        },
        CheckInLog: {
            view: true,
            exportCSV: true,
        },
        Devices: {
            view: true,
        },
        Displays: {
            view: true,
        },
        CastingMirroring: {
            view: true,
        },
        JobAccountingII: {
            view: true,
            edit: true,
        },
        Tenant: {
            view: true
        },
        CalendarAccount: {
            view: true,
            addGWAccount: true,
            removeGWAccount: true
        },

    };

    constructor() { }

    set adminType(adminRole: string) {
        this.adminRole = adminRole;
        this.setSatesBasedOnRole(this.adminRole);
    }

    set rmmAdminType(rmmAdminRole: string) {
        this.rmmAdminRole = rmmAdminRole;
        this.setSatesBasedOnRmmRole(this.rmmAdminRole);
    }

    setSatesBasedOnRole(adminType) {
        if (adminType === 'Support Admin') {
            this.componentStates.MobileUsers = {
                view: true,
                addUserImport: true,
                addUserGroup: true,
                addUserImportCSV: false,
                removeUser: false,
                licenseConfig: true,
                addImportIdCardNumber: false,
                idCardColumn: false,
            };
            this.componentStates.Workspaces = {
                view: true,
                addWorkspaceManual: false,
                addWorkSpaceImport: false,
                addWorkspaceWithGroup: false,
                addWorkspaceImportCSV: false,
                removeWorkspace: false,
                editWorkspace: false,
                registerDevice: false,
                addMFP: false,
                removeMFP: false,
                addDisplay: false,
                removeDisplay: false,
                editDisplay: false,
                configureDisplay: false,
                editDevice: false,
                voiceConfigure: false,
                deleteDevice: false,
                licenseConfig: false,
            };
            this.componentStates.DeviceAndAgents = {
                view: true,
                editSettings: false,
                editDisplay: false,
                editCasting: false,
                configureDisplay: false,
                reDiscover: false,
            };
            this.componentStates.AdminUsers = {
                view: false,
                addAdmin: false,
                assignRole: false,
                removeAdmin: false,
            };
            this.componentStates.SupportedDomains = {
                view: true,
                refreshDomainList: false,
                editDomains: false,
            };
            this.componentStates.NotificationPreferences = {
                view: true,
                edit: false,
            };
            this.componentStates.Alerts = {
                view: true,
                edit: false,
            };
            this.componentStates.Analytics = {
                view: false,
                exportCSV: false,
            };
            this.componentStates.Downloads = {
                view: true,
                mfpAgent: false,
                SNMPConfigurationLink: false,
                displayAgent: false,
                commonInstaller: false,
                commonInstallerWithConfig: false,
                outlookConfiguration:false
            };
            this.componentStates.SystemLog = {
                view: true,
                exportCSV: true,
            };
            this.componentStates.AdminLog = {
                view: true,
                exportCSV: true,
            };
            this.componentStates.AgentUpdates = {
                view: true,
                updateAgent: false,
                updatePolicy: false,
            };
            this.componentStates.CheckInLog = {
                view: true,
                exportCSV: true,
            };
            this.componentStates.Devices = {
                view: true,
            };
            this.componentStates.Displays = {
                view: true,
            };
            this.componentStates.CastingMirroring = {
                view: true,
            };
            this.componentStates.JobAccountingII = {
                view: false,
                edit: false,
            };
            this.componentStates.Agents = {
                view: true,
            }
            this.componentStates.AgentsAndDeviceSummary = {
                view: true,
                edit: true,
            }
            this.componentStates.Tenant = {
                view: false
            };
            this.componentStates.CalendarAccount ={
                view: true,
                addGWAccount: false,
                removeGWAccount: false,

            };
        }
    }

    setSatesBasedOnRmmRole(rmmAdminType) {
        switch (rmmAdminType) {
            case 'Primary Admin':
                this.rmmComponentStates.AdminUsers = {
                    view: true,
                    addGuest: true,
                    addUser: true,
                    deleteUser: true,
                    updateUser: true,
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: true,
                    refreshDomainList: true,
                    editDomains: true,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: true,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.TechnicalService = {
                    view: false,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: true,
                }
                this.rmmComponentStates.storageBackup = {
                    view: true,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: false,
                }
                this.rmmComponentStates.printerDriver = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: true,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: true,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: true,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.OperationLog = {
                    view: true,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: true,
                }
                this.rmmComponentStates.AdminLog = {
                    view: true,
                }
                this.rmmComponentStates.About = {
                    view: true,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: false,
                }
                this.rmmComponentStates.connectWise = {
                    view: false,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: false,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: true,
                }
                this.rmmComponentStates.alerts = {
                    view: false,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: false,
                }
                this.rmmComponentStates.GetData = {
                    view: false,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: true,
                }
                this.rmmComponentStates.Tenant= {
                    view: true
                }
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: false,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: true,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                break;
            case 'IT Main':
                this.rmmComponentStates.AdminUsers = {
                    view: true,
                    addGuest: true,
                    addUser: true,
                    deleteUser: true,
                    updateUser: true,
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: true,
                    refreshDomainList: true,
                    editDomains: true,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: true,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.TechnicalService = {
                    view: false,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: true,
                }
                this.rmmComponentStates.storageBackup = {
                    view: true,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: true,
                }
                this.rmmComponentStates.printerDriver = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: true,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: true,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: true,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.OperationLog = {
                    view: true,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: true,
                }
                this.rmmComponentStates.AdminLog = {
                    view: true,
                }
                this.rmmComponentStates.About = {
                    view: true,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: false,
                }
                this.rmmComponentStates.connectWise = {
                    view: false,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: false,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: true,
                }
                this.rmmComponentStates.alerts = {
                    view: false,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: false,
                }
                this.rmmComponentStates.GetData = {
                    view: false,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: true,
                }
                this.rmmComponentStates.Tenant = {
                     view: true
                },
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: false,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: true,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                break;
            case 'IT Helpdesk':
                this.rmmComponentStates.AdminUsers = {
                    view: false,
                    addGuest: false,
                    addUser: false,
                    deleteUser: false,
                    updateUser: false,
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: false,
                    refreshDomainList: false,
                    editDomains: false,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: true,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: false,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: false,
                }
                this.rmmComponentStates.storageBackup = {
                    view: false,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: false,
                }
                this.rmmComponentStates.printerDriver = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: false,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: false,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: true,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.OperationLog = {
                    view: false,
                }
                this.rmmComponentStates.System = {
                    view: true,
                }
                this.rmmComponentStates.TechnicalService = {
                    view: false,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: false,
                }
                this.rmmComponentStates.AdminLog = {
                    view: false,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: false,
                }
                this.rmmComponentStates.connectWise = {
                    view: false,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: false,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: false,
                }
                this.rmmComponentStates.alerts = {
                    view: false,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: false,
                }
                this.rmmComponentStates.GetData = {
                    view: false,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: false,
                }
                this.rmmComponentStates.Tenant = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: false,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: false,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                break;
            case 'Service Main':
                this.rmmComponentStates.AdminUsers = {
                    view: true,
                    addGuest: true,
                    addUser: false,
                    deleteUser: true,
                    updateUser: true,
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: true,
                    refreshDomainList: true,
                    editDomains: true,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: true,
                }
                this.rmmComponentStates.storageBackup = {
                    view: true,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: false,
                }
                this.rmmComponentStates.printerDriver = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: true,
                }
                this.rmmComponentStates.TechnicalService = {
                    view: true,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: true,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: true,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.OperationLog = {
                    view: true,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: true,
                }
                this.rmmComponentStates.AdminLog = {
                    view: true,
                }
                this.rmmComponentStates.About = {
                    view: true,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: true,
                }
                this.rmmComponentStates.connectWise = {
                    view: true,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: true,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: true,
                }
                this.rmmComponentStates.alerts = {
                    view: true,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: true,
                }
                this.rmmComponentStates.GetData = {
                    view: true,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: true,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: true,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: false,
                }
                this.rmmComponentStates.Tenant = {
                    view: false
                }
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: true,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: true,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: false,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: true,
                }
                break;
            case 'Service Support':
                this.rmmComponentStates.AdminUsers = {
                    view: false,
                    addGuest: false,
                    addUser: false,
                    deleteUser: false,
                    updateUser: false
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: false,
                    refreshDomainList: false,
                    editDomains: false,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: true,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: false,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: true,
                }
                this.rmmComponentStates.storageBackup = {
                    view: true,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: false,
                }
                this.rmmComponentStates.printerDriver = {
                    view: true,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: false,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: false,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: true,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.OperationLog = {
                    view: false,
                }
                this.rmmComponentStates.System = {
                    view: true,
                },
                this.rmmComponentStates.TechnicalService = {
                    view: false,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: false,
                }
                this.rmmComponentStates.AdminLog = {
                    view: false,
                }
                this.rmmComponentStates.About = {
                    view: true,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: false,
                }
                this.rmmComponentStates.connectWise = {
                    view: false,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: false,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: false,
                }
                this.rmmComponentStates.alerts = {
                    view: false,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: false,
                }
                this.rmmComponentStates.GetData = {
                    view: false,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: false,
                }
                this.rmmComponentStates.Tenant = {
                    view: false
                }
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: false,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: false,
                },
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                break;
            case 'Service View Only':
                this.rmmComponentStates.AdminUsers = {
                    view: false,
                    addGuest: false,
                    addUser: false,
                    deleteUser: false,
                    updateUser: false,
                };
                this.rmmComponentStates.SupportedDomains = {
                    view: false,
                    refreshDomainList: false,
                    editDomains: false,
                }
                this.rmmComponentStates.MFPPrinters = {
                    view: true,
                }
                this.rmmComponentStates.displays = {
                    view: true,
                }
                this.rmmComponentStates.rmmDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmMultiTenantDashboard = {
                    view: true,
                }
                this.rmmComponentStates.rmmGROP = {
                    view: false,
                }
                this.rmmComponentStates.rmmCustomDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmTasks = {
                    view: true,
                }
                this.rmmComponentStates.CreateSchedule = {
                    view: false,
                }
                this.rmmComponentStates.rmmDeviceCloning = {
                    view: false,
                }
                this.rmmComponentStates.storageBackup = {
                    view: false,
                }
                this.rmmComponentStates.rmmaddressbook = {
                    view: false,
                }
                this.rmmComponentStates.printerDriver = {
                    view: false,
                }
                this.rmmComponentStates.rmmDeviceType = {
                    view: false,
                }
                this.rmmComponentStates.rmmSecurityControl = {
                    view: true,
                }
                this.rmmComponentStates.rmmCreatePolicy = {
                    view: false,
                }
                this.rmmComponentStates.securityReport = {
                    view: true,
                }
                this.rmmComponentStates.fleetReport = {
                    view: false,
                }
                this.rmmComponentStates.usageReport = {
                    view: true,
                }
                this.rmmComponentStates.Agent = {
                    view: false,
                }
                this.rmmComponentStates.rmmEmailAlert = {
                    view: true,
                }
                this.rmmComponentStates.System = {
                    view: true,
                }
                this.rmmComponentStates.TechnicalService = {
                    view: false,
                }
                this.rmmComponentStates.OperationLog = {
                    view: false,
                }
                this.rmmComponentStates.DeviceLog = {
                    view: false,
                }
                this.rmmComponentStates.AdminLog = {
                    view: false,
                }
                this.rmmComponentStates.About = {
                    view: true,
                }
                this.rmmComponentStates.CounterVariation = {
                    view: false,
                }
                this.rmmComponentStates.connectWise = {
                    view: false,
                }
                this.rmmComponentStates.rmmCeoJuice = {
                    view: false,
                }
                this.rmmComponentStates.AgentDownloads = {
                    view: false,
                }
                this.rmmComponentStates.alerts = {
                    view: false,
                }
                this.rmmComponentStates.ServiceReport = {
                    view: false,
                }
                this.rmmComponentStates.GetData = {
                    view: false,
                }
                this.rmmComponentStates.RemoteMaintenance = {
                    view: false,
                }
                this.rmmComponentStates.FirmwareUpdate = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdate = {
                    view: false,
                }
                this.rmmComponentStates.Tenant = {
                    view: false
                }
                this.rmmComponentStates.FirmwareUpdate2 = {
                    view: false,
                }
                this.rmmComponentStates.rmmagentlog = {
                    view: false,
                }
                this.rmmComponentStates.rmmdevicesfirmwareupdateeu = {
                    view: false,
                }
                this.rmmComponentStates.rmmevatic = {
                    view: false,
                }
                break;
        }
    }

    set calendarAccountMenu(tntInfo: any) {
        if (tntInfo.provider != 'google-apps' || !tntInfo.hasMeetingLicense) {
            this.componentStates.CalendarAccount = {
                view: false,
                addGWAccount: false,
                removeGWAccount: false
            };
        }
    }

}
