import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../layout/workspace/workspace-list/interfaces/room';
import { UserAuth0 } from '../../layout/workspace/workspace-list/interfaces/room';
import { TokenStorage } from '../../core/token.storage';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { LogHandlerService } from './log-handler.service';
import { map } from 'rxjs/operators';
import { ProgressLoaderService } from './progress-loader.service';

@Directive()
@Injectable()
export class UserService {
    private getUserNameUrl: string;
    private _userSettings = {};
    private userData: UserAuth0;
    private connectionName: string;
    public loggdInUserDetails: User;
    public serviceTypeVal: string;
    public eulaVersion = '';
    private getTenantUrl: string;
    private SERVICETYPE = {
        'rocketstart': 'room',
        'marvel': 'user',
        'rmm': 'rmm',
        'scp': 'scpbase'
    }
    @Output() change: EventEmitter<string> = new EventEmitter();
    @Output() showChildList: EventEmitter<string> = new EventEmitter();

    constructor(private http: HttpClient, private token: TokenStorage, private authService: AuthService, private logHandlerService: LogHandlerService, private progressLoader: ProgressLoaderService) {
        this.loggdInUserDetails = null;
        if (environment.stackRegion === "EU") {
            this.eulaVersion = environment.optional.feat_scpv1 == 'true' ? '1.5' : '1.4';
        } else {
            this.eulaVersion = '1.3';
        }
    }
    dataChange: BehaviorSubject<any> = new BehaviorSubject<any>({ Users: [], PaginationHeaders: {} });
    dataChangeStatus: Subject<boolean> = new Subject<boolean>();
    private sort_field_mapping = {'Admin': 'userName', 'Role': 'adminType', 'Email': 'email',
        'users': 'userName', 'emailAddress': 'email', 'role': 'rmmRole'
    };

     // Return Users and response headers
     get entireData(): any {
        return this.dataChange.value;
    }

    public get userSettings() {
        return this._userSettings;
    }
    public set userSettings(value) {
        this._userSettings = value;
    }

    private _roomListSettings = { View: 'all', Search: '' };
    public get roomListSettings() {
        return this._roomListSettings;
    }
    public set roomListSettings(value) {
        this._roomListSettings = value;
    }

    get isLoggedIn() {
        if (this.token.getToken()) {
            return true;
        }
        return false;
    }


    setDefaultSettings() {
        this._roomListSettings = { View: 'all', Search: '' };
    }

    getUsersWithPagination(pageSize: string, paginationToken: any, sortBy:string, sortOrder:string, queryOptions: any) {
        const headers = {
            'Content-Type': 'application/json',
            'Pagination-Supported': 'true',
            'user-role': 'ba'
        }

        if(pageSize) {
            headers['Page-Size'] =  pageSize.toString();
        }
        if(paginationToken) {
            headers['Page-Token'] =  paginationToken;
        }

        let queryString = this.jsonToQueryString(queryOptions);

        // Add 'sortBy' in the query string
        if(sortBy) {
            let mappingField = this.sort_field_mapping[sortBy];
            if( sortBy == "role")
            {
                mappingField = 'rmmRole';
            }
            queryString += `&sortBy=${mappingField}`
        }

        // Add 'sortOrder' in the query string
        if(sortBy && sortOrder) {
            queryString += `&sortOrder=${sortOrder}`
        }

        const httpOptions = {
            headers: new HttpHeaders(headers),
            observe: 'response' as 'body'
        };
        return this.http
            .get<any>(
                environment.rmpEndPointUser.url + '/users?' + queryString,
                httpOptions
            )
            .subscribe(data => {
                const response_headers = {
                    'page-token': data.headers.get('page-token'),
                    'total-count' : data.headers.get('total-count')
                }
                // Assign Users and Headers
                data.Users = data.body;
                data.PaginationHeaders = response_headers;
                this.dataChangeStatus.next(true);
                this.dataChange.next(data);
            });
    }

    /**
     *  End User has agreed to the terms of EULA
     */
    eulaTermsAgreed(agreed: boolean, guest?: boolean) {
        if (guest) {
            this.queryGuestUser({ email: this.authService.userUpn }).toPromise().then((result) => {
                let guestRecord;
                for (let user of result) {
                    if (user.auth0ConnectionName == 'guest-shared') {
                        guestRecord = user;
                    }
                }
                guestRecord['isPortalEulaAccepted'] = agreed;
                guestRecord['portalEulaVersion'] = this.eulaVersion;
                this.updateUser(guestRecord).toPromise().then((data) => {
                    console.log(`eulaAccepted and updated the version to: ${this.eulaVersion}`);
                }).catch((error) => {
                    console.error(`Error when updating user in eula Accepted`);
                    console.log(error);
                });
            })
        } else {
            const updateTenant = this.authService.tenantInfo;
            updateTenant['isPortalEulaAccepted'] = agreed;
            updateTenant['portalEulaVersion'] = this.eulaVersion;
            updateTenant['eulaAcceptedBA'] = this.authService.userEmail;
            this.updateTenant(updateTenant).toPromise().then((data) => {
                this.authService.tenantInfo = data;
                console.log(`updated tenant in eula Accepted email: ${this.authService.tenantInfo.eulaAcceptedBA} and update the version:
                ${this.authService.tenantInfo.portalEulaVersion}`);
            }).catch((error) => {
                console.error(`Error when updating tenant in eula Accepted`);
                console.log(error);
            });
        }
    }

    /**
     * This method will return a promise which will provide the userData (from Auth0)
     * If the userData is already in cache, the promise will fire after 100ms with cached userData
     * else, the userData will be retrieved using api /api/userAuth0
     */
    usingAuthData(): Promise<UserAuth0> {
        return new Promise((resolve, reject) => {
            if (this.userData != null) {
                setTimeout(() => {
                    resolve(this.userData);
                }, 100);
            } else {
                const authInfo = this.getAuth0UserData().toPromise();
                authInfo.then(userData => {
                    this.progressLoader.show();
                    this.userData = userData;
                    this.connectionName = this.authService.getConnectionName;
                    resolve(userData);
                }).catch(error => reject(error));
            }
        });
    }

    /**
     * Get the user data from auth0
     * TODO: Move user-id parameter from header to query string (if possible)
     */
    getAuth0UserData(): Observable<UserAuth0> {
        //console.log(`getAuth0UserDat(user-id: ${this.authService.auth0userId})`);
        this.getUserNameUrl = `${environment.rmpEndPointUser.url}/userAuth0`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-id': this.authService.auth0userId,
                'Authorization': 'Bearer ' + this.authService.accessToken
            }),
        };
        return this.http.get<UserAuth0>(this.getUserNameUrl, httpOptions);
    }

    /**
     * Get the user details from DB
     */
    getUser(id): Observable<User> {
        // console.log(`function getUser(id: ${id});`);
        this.getUserNameUrl = `${environment.rmpEndPointUser.url}/users?id=${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken
            }),
        };
        return this.http.get<User>(this.getUserNameUrl, httpOptions);
    }

    /**
     * Create a new record for the currently logged-in user in DB
     */
    createUsers(users: User[], role = 'user', service): Observable<User> {
        // console.log(`function createUser(user: ${JSON.stringify(user)})`);
        const userRole = role || 'user';
        this.getUserNameUrl = `${environment.rmpEndPointUser.url}/users`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'feature_type': this.SERVICETYPE[this.token.getMarvelRMPService()],
                'user-limit': (environment.userLimit || 5000).toString(),
                'cache-control': 'no-cache',
                'service': service
            }),
        };
        return this.http.put<User>(this.getUserNameUrl, users, httpOptions).pipe(map((resp) => {
            if (role.toLowerCase() === 'ba') {
            const adminAdded = users.map((item) => item.userInfo.email).join(', ');
            this.logHandlerService.addAdminLog('Admin', 'Admin user added', 'MAA00027', adminAdded);
            }
            return resp;
        }));
    }

    /**
     * Update the record for the currently logged-in user in DB
     * @param {User} user The user instance
     */
    updateUser(user: User, role = 'user'): Observable<User> {
        let guest = 'false';
        if (user.auth0ConnectionName == 'guest-shared') {
            guest = 'true';
        }
        // console.log(`function updateUser(user: ${JSON.stringify(user)})`);
        const userRole = role || 'user';
        this.getUserNameUrl = `${environment.rmpEndPointUser.url}/users/${user.id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'feature_type': this.SERVICETYPE[this.token.getMarvelRMPService()],
                'guest': guest
            }),
        };
        return this.http.put<User>(this.getUserNameUrl, user, httpOptions);
    }

    /**
     * Update the record for the tenant in DB
     * @param {tenant} tenant The tenant instance
     * @param {domainUpdate} domainUpdate For handling state of domain update
     */
    updateTenant(tenant: any, domainUpdate = 'no'): Observable<any> {
        this.getTenantUrl = `${environment.rmpEndPointTenant.url}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'domain-update': domainUpdate,
                'feature_type': this.SERVICETYPE[this.token.getMarvelRMPService()]
            }),
        };
        return this.http.put<any>(this.getTenantUrl, tenant, httpOptions);
    }

    /**
     *  Convert an object to query string
     * @param {any} json The query params as an object
     */
    jsonToQueryString(json: any): String {
        const entries = Object.keys(json).map(key => {
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            });
        return entries.join('&');
    }
    /**
     * Query the user id from DB
     * @param {any} queryOptions The query parameters as an object
     * TODO: Rewrite the query API with following changes:
     *  1. Return only one id instead of an array with single element
     *  2. The request params should be in query string instead of request headers
     */
    queryUser(queryOptions: any, role = 'user', sortBy: string, sortOrder: string): Observable<any> {
        // console.log(`function queryUser(queryOptions: ${JSON.stringify(queryOptions)})`);
        let queryString = this.jsonToQueryString(queryOptions);
        // Add 'sortBy' in the query string
        if (sortBy) {
            queryString += `&sortBy=${this.sort_field_mapping[sortBy]}`
        }
        // Add 'sortOrder' in the query string
        if (sortBy && sortOrder) {
            queryString += `&sortOrder=${sortOrder}`
        }
        const queryUserUrl = `${environment.rmpEndPointUser.url}/users?${queryString}`;
        const userRole = role || 'user';
        console.log(`queryUserUrl: ${queryUserUrl}`);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                'Authorization': 'Bearer ' + this.authService.accessToken
            }),
        };
        return this.http.get<any>(queryUserUrl, httpOptions);
    }

    /**
     * Query the guest user from DB
     * @param {any} queryOptions The query parameters as an object
     * TODO: Rewrite the query API with following changes:
     *  1. Return only one id instead of an array with single element
     *  2. The request params should be in query string instead of request headers
     */
    queryGuestUser(queryOptions: any, role = 'user'): Observable<any> {
        let queryString = this.jsonToQueryString(queryOptions);
        let guest = 'true';
        const queryUserUrl = `${environment.rmpEndPointUser.url}/users?${queryString}`;
        const userRole = role || 'user';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'guest': guest
            }),
        };
        return this.http.get<any>(queryUserUrl, httpOptions);
    }

    /**
     * Update the record for the currently logged-in user in DB
     * @param {User} user The user instance
     */
    deleteUser(user: User, role = 'user', service?): Observable<any> {
        // console.log(`function updateUser(user: ${JSON.stringify(user)})`);
        this.getUserNameUrl = `${environment.rmpEndPointUser.url}/users/`;
        const userRole = role || 'user';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'feature_type': this.SERVICETYPE[this.token.getMarvelRMPService()],
                'cache-control': 'no-cache',
                'content-length': '121',
                'service': service
            }),
            body: { 'userIds': [user.id] },
        };
        return this.http.delete<any>(this.getUserNameUrl, httpOptions).pipe(map((resp) => {
            if (role.toLowerCase() === 'ba') {
                this.logHandlerService.addAdminLog('Admin', 'Admin user deleted', 'MAA00028', user.userInfo.email);
            }
            return resp;
        }));
    }


    /**
     * Retrieve the graph (svg) file from the report server
     * @param graphUrl The url of the graph
     *
     * @returns An observable, the component can subscribe to to read the svg content.
     */
    getGraphContent(graphUrl): Observable<any> {
        /**
         * Note: The HttpClient.get method (from Angular 5 or later) is designed for json response.
         * Since we are using this method for retrieving xml data (svg file), the current combination of
         * httpOptions is the only way this works without any typescript compilation errors.
         */
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.authService.accessToken
                // 'sharp-tenantid': 'sharpsoftwaredev-waad'
            }),
            responseType: 'text' as 'text'
        };
        return this.http.get(graphUrl, httpOptions) as Observable<string>;
    }
    setMvlRmpStatus(status) {
        this.serviceTypeVal = status;
        this.change.emit(this.serviceTypeVal);
    }

    /**
     * Retrieve usable and unusable domain alias information from SSP
     * @param domains domain aliases to filter
     *
     */
    getDomainsAliasInfo(domains): Observable<any> {
        const queryUserUrl = `${environment.domainsURL.url}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'domains': domains,
            }),
        };
        return this.http.get<any>(queryUserUrl, httpOptions);
    }
}
