<mat-card class="custom_system_log">
    <div class="fx_space-between-center">
        <mat-card-title>{{ 'SystemLog' | translate }}</mat-card-title>
    </div>

    <mat-card-content>
        <div  class="p_tb fx_row">
            <form  class="no_wrap fx_90">

                <button class="button" mat-button type="submit" id='id-systemLog-AdminButton' name="fontStyle"
                    aria-label="Font Style" (click)="serviceSelected('Admin Portal')" class=""
                    [ngClass]="{'btn_toggle_on' : isAdminPortal, 'btn_toggle_off': !isAdminPortal}">
                    <img src="./../../../assets/images/adminportal.svg" width="32" alt="Admin Portal">
                    {{'AdminPortal' | translate}}</button>

                    <button *ngIf="viewScp" style="margin-left: 20px;" class="button" mat-button type="submit" id='id-systemLog-AdminButton' name="fontStyle" aria-label="Font Style"
                        (click)="serviceSelected('Synappx Cloud Print')" class=""
                        [ngClass]="{'btn_toggle_on' : isCloudPrint, 'btn_toggle_off': !isCloudPrint}">
                        <img src="./../../../assets/images/scp_org.svg" width="32" alt="Synappx Cloud Print">
                        {{'Synappx Cloud Print' | translate}}</button>
            </form>

            <div class="text_left fx_space-between-center">
                <mat-form-field class="margin_10">
                    <mat-label>{{'ChooseStartDate' | translate}}</mat-label>
                    <input matInput id="startDate" #startDate (click)="picker.open()" readonly [matDatepicker]="picker"
                         [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="margin_25">
                    <mat-label>{{'ChooseEndDate' | translate}}</mat-label>
                    <input matInput id="endDate" #endDate (click)="picker1.open()" readonly [matDatepicker]="picker1"
                        [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <span>
                    <button class="primary_btn" mat-raised-button type="submit" id='id-deviceEdit-SaveButtton'
                        (click)="getExport(startDate.value,endDate.value)">{{'Export' | translate}}</button>
                </span>

                <div class="errormessage">
                    <div *ngIf="error.isError">{{error.errorMessage}}</div>
                </div>
            </div>
        </div>

        <div class="fx_row">
            <form class="fx_20" [formGroup]="filterForm">
                <mat-form-field>
                    <mat-label>{{ 'View' | translate }}</mat-label>
                    <mat-select id="user-select-type-id" (selectionChange)="selectedUser($event)" formControlName="View"
                        class="list_log_value" color="accent" #view>
                        <mat-option [value]="0"> {{ 'AllUsersAgents'| translate }}</mat-option>
                        <mat-option *ngFor="let userOrAgent of userOrAgentList;" [value]="userOrAgent">
                            {{userOrAgent}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
                <form class="fx_97" [formGroup]="categoryForm">
                    <mat-form-field>
                        <mat-label>{{ 'View' | translate }}</mat-label>
                        <mat-select id="user-select-type-id" (selectionChange)="selectedCategory($event)" formControlName="Category"
                            class="list_log_value" color="accent" #view>
                            <mat-option [value]="0"> {{ 'AllCategories'| translate }}</mat-option>
                            <mat-option *ngFor="let category of categoryList;" [value]="category">
                                {{category}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>

            <div class="text_right fx_space-between-center">
                <button class="icon_btn" mat-icon-button id="sspAdminLog" 
                    matTooltip="{{'refreshList' | translate}}" (click)="refreshPage()" matTooltipPosition=above>
                    <mat-icon aria-label="Example icon-button with a refresh icon">refresh</mat-icon>
                </button>
            </div>
        </div>

        <div class="example-container mat-elevation-z8 mt_15">
            <mat-table class="lessons-table" [dataSource]="dataSource" matSort class="mat-cell" matSortDisableClear>
                <!-- Log Date Column -->
                <ng-container matColumnDef="log_generated_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='log_generated_date' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'LogDate' | translate}} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{formatDate(element._source.log_generated_date)}}</mat-cell>
                </ng-container>
                <!-- User Column -->
                <ng-container matColumnDef="user_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='user_name' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'UserAgentName' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="word_break">{{ element._source.user_name }}</mat-cell>
                </ng-container>
                <!-- Application Column -->
                <ng-container matColumnDef="application">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='application' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Application' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="removeSpace"> {{ element._source.application}}</mat-cell>
                </ng-container>
                <!-- Category Column -->
                <ng-container matColumnDef="log_category">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='log_category' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Category' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="removeSpace word_break">{{ element._source.log_category
                        }}</mat-cell>
                </ng-container>
                <!-- Error Column -->
                <ng-container matColumnDef="log_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='log_type' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Error' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="word_break">
                        <div *ngIf="element._source.detail else no_detail">
                            <div *ngIf="element._isMfpRemoveEnabled"
                                (click)='removeMFPDevice(element._source.detail,element._source.device_id, element._source.agent_id)'
                                matTooltip="{{ element._source.detail }}" matTooltipClass="linefeed-tooltip"
                                matTooltipPosition=above class="link_name">
                                {{element._source.log_type_id}}
                            </div>
                            <div *ngIf="!element._isMfpRemoveEnabled">
                                <span matTooltip="{{ element._source.detail }}" matTooltipClass="linefeed-tooltip"
                                    matTooltipPosition=above>{{element._source.log_type_id }}</span>
                            </div>
                        </div>
                        <ng-template #no_detail>
                            <span>{{ element._source.log_type_id }}</span>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            </mat-table>
            <div class="no-results" *ngIf="dataSource === null || dataSource && dataSource.length === 0">
                <mat-card-content><label>{{ 'NoLogsAvailable' | translate}}</label></mat-card-content>
            </div>
            <div class="no-results" *ngIf="loadingStatus">
                <mat-card-content><label>{{ 'Loading' | translate}}</label>
                </mat-card-content>
            </div>
            <mat-paginator #paginator class="custom_mat_paginator" [length]="totalCount" [pageIndex]="0"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPagination($event)" showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>