import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
    selector: 'app-warning-action-dialog',
    templateUrl: './warning-action-dialog.component.html',
    styleUrls: ['./warning-action-dialog.component.scss'],
})
export class WarningActionDialogComponent extends BaseComponent {
    constructor(public warningActionDialogRef: MatDialogRef<WarningActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
    }

    close() {
        this.warningActionDialogRef.close(0);
    }
}
