<form
  novalidate
  autocomplete="off"
  validateFormAndScrollToFirstError
  [formGroup]="settingsFormGroup"
  (ngSubmit)="saveSettings()"
  *ngIf="!hasError; else showInternalErrorMessage"
>
  <mat-card *ngIf="isAdminUser">
    <mat-card-content>
      <h3 class="mat-headline-6 pt-3">{{ 'scp.settings.login.default_login.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.default_login.fields.method' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [formControl]="defaultLoginFormCtrl" (selectionChange)="changeDefaultLogin()">
            <mat-option value="1">{{ 'scp.settings.login.default_login.fields.method_qr_code' | translate }}</mat-option>
            <mat-option value="2">{{ 'scp.settings.login.default_login.fields.method_pin' | translate }}</mat-option>
            <mat-option value="3">{{ 'scp.settings.login.default_login.fields.method_card' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>

    <mat-card-content>
      <h3 class="mat-headline-6">{{ 'scp.settings.login.pin.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_login_enable' | translate }}</mat-label>
        <mat-slide-toggle [formControl]="enablePinFormCtrl" (change)="update2faSetting()"> </mat-slide-toggle>
      </div>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_auto_generate' | translate }}</mat-label>
        <mat-slide-toggle [formControl]="enablePinAutoGenerateFormCtrl" (change)="updatePinAutoGenerateSetting()"> </mat-slide-toggle>
      </div>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_length' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [formControl]="pinMinDigitFormCtrl">
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_max_attempts' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [formControl]="pinMaxAttemptsFormCtrl">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
            <mat-option value="8">8</mat-option>
            <mat-option value="9">9</mat-option>
            <mat-option value="10">10</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_replacement' | translate }}</mat-label>
        <button type="button" mat-flat-button color="primary" class="mx-2" (click)="showPinReplacementDialog()">
          {{ 'scp.common.replace_button_text' | translate }}
        </button>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-content>
      <h3 class="mat-headline-6 pt-3">{{ 'scp.settings.login.card.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.card.fields.card_login_enable' | translate }}</mat-label>
        <mat-slide-toggle [formControl]="enableCardFormCtrl" (change)="update2faSetting()"> </mat-slide-toggle>
      </div>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.card.fields.card_max_attempts' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [formControl]="cardMaxAttemptsFormCtrl">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
            <mat-option value="8">8</mat-option>
            <mat-option value="9">9</mat-option>
            <mat-option value="10">10</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-content>
      <h3 class="mat-headline-6 pt-3">{{ 'scp.settings.login.2fa.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label> {{ 'scp.settings.login.2fa.fields.2fa_enable' | translate }} </mat-label>
        <mat-slide-toggle [formControl]="enable2FAFormCtrl" (change)="updatePinCardSetting()"> </mat-slide-toggle>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-content *ngIf="!isGoogle">
      <h3 class="mat-headline-6 pt-3">{{ 'scp.settings.login.guestprint.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.guestprint.fields.enable' | translate }} </mat-label>
        <mat-slide-toggle [formControl]="enableGuestPrintFormCtrl" (change)="updateGuestPrintSetting()"> </mat-slide-toggle>
      </div>

      <div class="scp_form_group" *ngIf="enableGuestPrintFormCtrl.value">
        <mat-label>{{ 'scp.settings.login.guestprint.user_validity.dialog_title' | translate }}</mat-label>
        <mat-form-field>
            <mat-select [formControl]="guestPrintUserValidityFormCtrl">
                <mat-option value="168">{{ 'scp.settings.login.guestprint.user_validity.7days' | translate }}</mat-option>
                <mat-option value="336">{{ 'scp.settings.login.guestprint.user_validity.14days' | translate }}</mat-option>
                <mat-option value="648">{{ 'scp.settings.login.guestprint.user_validity.21days' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
      </div>

      <div class="scp_form_group" *ngIf="enableGuestPrintFormCtrl.value">
        <mat-label>{{ 'scp.settings.login.guestprint.fields.email' | translate }} </mat-label>
        <mat-form-field class="guest_print_input">
          <input matInput type="email" [formControl]="guestPrintEmailFormCtrl" [readonly]="isGuestPrintConnected" email />
        </mat-form-field>
        <button *ngIf="!isGuestPrintConnected" type="button" mat-flat-button color="primary" class="mx-2" (click)="createGraphSubscription()">
          {{ 'scp.settings.login.guestprint.action.connect' | translate }}
        </button>
        <button *ngIf="isGuestPrintConnected" type="button" mat-flat-button color="primary" class="mx-2" (click)="deleteGraphSubscription()">
          {{ 'scp.settings.login.guestprint.action.disconnect' | translate }}
        </button>
      </div>

      <div class="scp_form_group" *ngIf="enableGuestPrintFormCtrl.value">
        <mat-label *ngIf="isGuestPrintConnected"> {{ 'scp.settings.login.guestprint.fields.status.connected' | translate }} </mat-label>
        <mat-label *ngIf="!isGuestPrintConnected"> {{ 'scp.settings.login.guestprint.fields.status.disconnected' | translate }} </mat-label>
        <div class="status-indicator" [ngClass]="guestPrintConnectionStatus"></div>
        <div *ngIf="isGuestPrintConnected">
          <span [innerHTML]="'scp.settings.login.guestprint.fields.status_expiry' | translate : { value: guestPrintExpiryDateFormCtrl.value }"> </span>
        </div>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions align="end" class="pt-4">
      <button mat-flat-button color="primary">{{ 'scp.common.update_button_text' | translate }}</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="!isAdminUser">
    <mat-card-content>
      <h3 class="mat-headline-6">{{ 'scp.settings.login.pin.heading' | translate }}</h3>

      <div class="scp_form_group">
        <mat-label>{{ 'scp.settings.login.pin.fields.pin_replacement' | translate }}</mat-label>
        <button type="button" mat-flat-button color="primary" class="mx-2" (click)="showPinReplacementDialog()">
          {{ 'scp.common.replace_button_text' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>

<ng-template #showInternalErrorMessage>
  <mat-card>
    <mat-card-content>
      <p class="mb-3">{{ 'scp.settings.data_loading_error_message' | translate }}</p>
    </mat-card-content>

    <mat-card-actions align="end" class="pt-4">
      <button mat-flat-button color="primary" (click)="getSettingsData()">{{ 'scp.common.try_again_button_text' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</ng-template>
