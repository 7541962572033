import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import  Chart  from 'chart.js/auto';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base.component';
import { SCPRouterLinks } from '../../shared/models/router-model';
import { IDevice, ITenantUser } from '../../shared/models/scp-model';
import { SetupGuideComponent } from './setup-guide/setup-guide.component';

const SCP_SHOW_GUIDE = 'scp-show-guide';
const SCP_PAGE_VISITED = 'scp-dashboard-page-visited';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  SCPRouterLinks = SCPRouterLinks;
  savedPaperData;
  computedData = {
    users: {
      total: 0,
      active: 0,
      inActive: 0,
    },
    devices: {
      total: 0,
      active: 0,
      inActive: 0,
    },
    licenses: {
      total: 0,
      active: 0,
      inActive: 0,
    },
  };

  totalPagesOutputChart: Chart<"pie", any[] ,any>;
  monthlyPagesOutputChart: Chart<"pie", any[], any>;
  yearlyPagesOutputChart: Chart<"bar", number[][], any>;

  constructor(private titleCasePipe: TitleCasePipe) {
    super();
  }

  ngOnInit() {
    const shouldShowGuide = this.window.localStorage.getItem(SCP_SHOW_GUIDE) !== 'no';
    const isFreshSession = !JSON.parse(this.window.sessionStorage.getItem(SCP_PAGE_VISITED));
    const dialogObs$ = this.dialog.openDialogs[0]?.afterClosed() || this.dialog.afterAllClosed;

    dialogObs$.pipe(this.takeUntilDestroyed(), first()).subscribe((dialogResult) => {
      this.getData();

      if (isFreshSession && shouldShowGuide && dialogResult !== 0) {
        this.showSetupGuideDialog();
      }
    });
  }

  ngOnDestroy() {
    if (this.totalPagesOutputChart) {
      this.totalPagesOutputChart.destroy();
    }

    if (this.monthlyPagesOutputChart) {
      this.monthlyPagesOutputChart.destroy();
    }

    if (this.yearlyPagesOutputChart) {
      this.yearlyPagesOutputChart.destroy();
    }

    super.ngOnDestroy();
  }

  showSetupGuideDialog() {
    this.window.localStorage.removeItem(SCP_SHOW_GUIDE);
    this.window.sessionStorage.removeItem(SCP_PAGE_VISITED);

    this.dialog
      .open(SetupGuideComponent, {
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<string>())
      .subscribe((dialogResult: 'yes' | 'no') => {
        this.window.localStorage.setItem(SCP_SHOW_GUIDE, dialogResult);
        this.window.sessionStorage.setItem(SCP_PAGE_VISITED, JSON.stringify(true));
      });
  }

  getData() {
    forkJoin([
      this.appService.getUsers(), // prettier-ignore
      this.appService.getPrinters(),
      this.appService.getLicenseInfo(),
      this.appService.getTenantPaperSaved(this.appService.userDetails.tenantGuid),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([usersData, devicesData, licensesData, paperSavedData]) => {
        const defaultData = { jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0 };

        this.savedPaperData = paperSavedData || { tenantPrintJobs: { currentPeriod: { ...defaultData }, previousPeriod: { ...defaultData } } };

        this.computeUsersData(usersData || []);
        this.computeDevicesData(devicesData || []);
        this.computeLicensesData(licensesData?.subscriptions?.scp || {});

        this.prepareCharts();
      });
  }

  computeUsersData(usersData: ITenantUser[]) {
    this.computedData.users.total = usersData.length;

    usersData.forEach((user) => {
      if (user.enabled) {
        this.computedData.users.active++;
      } else {
        this.computedData.users.inActive++;
      }
    });
  }

  computeDevicesData(devicesData: IDevice[]) {
    this.computedData.devices.total = devicesData.length;

    devicesData.forEach((device) => {
      if (device.activated) {
        this.computedData.devices.active++;
      } else {
        this.computedData.devices.inActive++;
      }
    });
  }

  computeLicensesData(scpLicensesData) {
    this.computedData.licenses.total = scpLicensesData.totalLicenses || 0;
    this.computedData.licenses.active = scpLicensesData.assigned || 0;
    this.computedData.licenses.inActive = scpLicensesData.unassigned || 0;
  }

  prepareCharts() {
    this.totalPagesOutputChart = new Chart('totalPagesOutputChart', {
      type: 'pie',
      data: {
        labels: [
          this.translate.instant('scp.dashboard.charts.totalPagesOutput.color_legend_text'),
          this.translate.instant('scp.dashboard.charts.totalPagesOutput.mono_legend_text'),
        ],
        datasets: [
          {
            data: [this.savedPaperData?.tenantTotalPrintedPageColour || 0, this.savedPaperData?.tenantTotalPrintedPageMono || 0],
            backgroundColor: ['rgba(54, 162, 235, 1)', 'rgba(54, 162, 235, .6)'],
          },
        ],
      },
      options: {
        plugins: {
          title: { display: true, text: this.translate.instant('scp.dashboard.charts.totalPagesOutput.title_text') },
          legend: { position: 'bottom' },
        },
        aspectRatio: 1,
      }
    });

    this.monthlyPagesOutputChart = new Chart('monthlyPagesOutputChart', {
      type: 'pie',
      data: {
        labels: [
          this.translate.instant('scp.dashboard.charts.monthlyPagesOutput.color_legend_text'),
          this.translate.instant('scp.dashboard.charts.monthlyPagesOutput.mono_legend_text'),
        ],
        datasets: [
          {
            data: [this.savedPaperData?.tenantTotalPrintedPageColourThisMonth || 0, this.savedPaperData?.tenantTotalPrintedPageMonoThisMonth || 0],
            backgroundColor: ['rgba(0, 0, 0, .7)', 'rgba(0, 0, 0, .3)'],
          },
        ],
      },
      options: {
        plugins: {
          title: { display: true, text: this.translate.instant('scp.dashboard.charts.monthlyPagesOutput.title_text') },
          legend: { position: 'bottom' },
        },
        aspectRatio: 1,
      },
    });

    this.yearlyPagesOutputChart = new Chart('yearlyPagesOutputChart', {
      type: 'bar',
      data: {
        labels: Object.keys(this.savedPaperData?.tenantPrintJobs?.currentPeriod).map((val) => {
          return this.translate.instant('scp.common.month.' + val);
        }),
        datasets: [
          {
            label: this.translate.instant('scp.dashboard.charts.yearlyPagesOutput.current_period_legend_text'),
            data: Object.values<number[]>(this.savedPaperData?.tenantPrintJobs?.currentPeriod),
            backgroundColor: 'rgba(54, 162, 235, 1)',
          },
          {
            label: this.translate.instant('scp.dashboard.charts.yearlyPagesOutput.previous_period_legend_text'),
            data: Object.values<number[]>(this.savedPaperData?.tenantPrintJobs?.previousPeriod),
            backgroundColor: 'rgba(54, 162, 235, .6)',
          },
        ],
      },
      options: {
        plugins: {
          title: { display: true, text: this.translate.instant('scp.dashboard.charts.yearlyPagesOutput.title_text') },
          legend: { position: 'bottom' },
        },
        // scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
      },
    });
      
  }
}
