<div class="scp_theme mat-typography scp_dialog">
    <h2 mat-dialog-title>{{ data.title }}</h2>

    <div class="mat-mdc-dialog-content">
        <div innerHTML="{{ data.message }}"></div>
    </div>

    <div mat-dialog-actions class="dialog-actions" align="end">
        <button mat-flat-button mat-dialog-close (click)="close()" class="mat-mdc-raised-button">{{ 'Ok' | translate }}</button>
    </div>
</div>