import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppMaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './shared/services/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { TokenStorage } from './core/token.storage';
import { AuthGuard } from './shared';
import { UserService } from './shared/services/user.service';
import { CallbackComponent } from './callback/callback.component';
import { EulaDialogUSENComponent } from './login/eulaDialog/eula.dialog.component';
import { NotificationMessageComponent } from './shared/components/notifications/notification-messages-component';
import { TenantService } from './shared/services/tenant.service';
import { GuestService } from './shared/services/guest.service';
import { CustomAdminService } from './shared/services/custom-admin.service'
import { BnNgIdleService } from 'bn-ng-idle';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from '../app/app.interceptor';
import { ProgressLoaderModule } from './shared/components/progress-loader/progress-loader.module';
import { ConfirmOperationComponent } from './layout/components/dialogs/confirm-operation/confirm-operation.component';
import { DialogProviderComponent } from './layout/components/dialogs/dialogprovider/dialogprovider.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ErrorLinkComponent } from './error-link/error-link.component';
import { EulaDialogEUENComponent } from './login/eulaDialog/eula.en.dialog.component';
import { EulaDialogEUDAComponent } from './login/eulaDialog/eula.da.dialog.component';
import { EulaDialogEUDEComponent } from './login/eulaDialog/eula.de.dialog.component';
import { EulaDialogEUESComponent } from './login/eulaDialog/eula.es.dialog.component';
import { EulaDialogEUFIComponent } from './login/eulaDialog/eula.fi.dialog.component';
import { EulaDialogEUFRComponent } from './login/eulaDialog/eula.fr.dialog.component';
import { EulaDialogEUITComponent } from './login/eulaDialog/eula.it.dialog.component';
import { EulaDialogEUNLComponent } from './login/eulaDialog/eula.nl.dialog.component';
import { EulaDialogEUNOComponent } from './login/eulaDialog/eula.no.dialog.component';
import { EulaDialogEUPLComponent } from './login/eulaDialog/eula.pl.dialog.component';
import { EulaDialogEUSVComponent } from './login/eulaDialog/eula.sv.dialog.component';
import { HttpTranslateLoaderFactory } from './shared/resource-loader';
import { UsersListService } from './layout/marvel/users/services/users-list.service';
import { GrantPermissionLoginComponent } from './layout/components/dialogs/grant-permission-login/grant-permission-login.component';
import { ConfirmActionDialogComponent } from './shared/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { RmmServiceGuard } from './shared/guard/rmmservice.guard';
import { GoServiceGuard } from './shared/guard/goservice.guard';
import { MeetingServiceGuard } from './shared/guard/meetingservice.guard';
import { ConfirmDeleteComponent } from './layout/components/dialogs/confirm-delete/confirm-delete.component';
import { AdminApprovalComponent } from './layout/components/dialogs/admin-approval/admin-approval.component';
import { SynappxEnvService as AuthEnvService } from 'auth-n';
import { AUTH_ENV } from './AuthEnv';
import { AppDataService, CloudPrintHttpInterceptor } from 'lib-ja-cloud';
import { globalAppData } from './shared/interfaces/AppData';
import { CloudPrintRuntimeService } from './shared/services/cloud-print-runtime.service';
import { CloudPrintEnvService } from 'lib-ja-cloud';
import { SCP_ENV } from './ScpEnv';
import { PERMISSION_GUARD } from './shared/guard/rmm-permission.guard';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [AppComponent,
        CallbackComponent,
        EulaDialogUSENComponent,
        EulaDialogEUENComponent,
        EulaDialogEUDAComponent,
        EulaDialogEUDEComponent,
        EulaDialogEUESComponent,
        EulaDialogEUFIComponent,
        EulaDialogEUFRComponent,
        EulaDialogEUITComponent,
        EulaDialogEUNLComponent,
        EulaDialogEUNOComponent,
        EulaDialogEUPLComponent,
        EulaDialogEUSVComponent,
        NotificationMessageComponent,
        ConfirmOperationComponent,
        DialogProviderComponent,
        ErrorLinkComponent,
        GrantPermissionLoginComponent,
        ConfirmActionDialogComponent,
        ConfirmDeleteComponent,
        AdminApprovalComponent],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpTranslateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        ProgressLoaderModule], providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CloudPrintHttpInterceptor,
            multi: true
        },
        {
            provide: AuthEnvService,
            useValue: AUTH_ENV
        },
        {
            provide: AppDataService,
            useValue: globalAppData
        },
        {
            provide: CloudPrintEnvService,
            useValue: SCP_ENV
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.googleTagId
        },
        AuthService, NotificationService, UserService, AuthGuard, RmmServiceGuard, PERMISSION_GUARD, GoServiceGuard, MeetingServiceGuard, TokenStorage, TenantService, BnNgIdleService, UsersListService, DeviceDetectorService, GuestService, CloudPrintRuntimeService, CustomAdminService,  provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
