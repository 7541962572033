import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { AuthService } from '../../../../../../src/app/shared/services/auth.service';
// import { environment } from '../../../../../../src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppDataService } from '../../../shared/services/local-app-data.service';

@Component({
  selector: 'app-guestprint-admin-approval',
  templateUrl: './guestprint-admin-approval.component.html',
  styleUrls: ['./guestprint-admin-approval.component.scss'],
})
export class GuestPrintAdminApprovalComponent implements OnInit {
  public tenantDomain: string;
  public azureAppId: string;
  public callbackUrl: string;
  public requiredScopes: string;
  public url: string;
  public userEmail: string;
  public PermissionsRequiredMessage: string;

  constructor(
    public confirmDialogRef: MatDialogRef<GuestPrintAdminApprovalComponent>,
    private localAppDataService: LocalAppDataService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.userEmail = this.localAppDataService.Upn;
    this.tenantDomain = this.userEmail.toLowerCase().split('@')[1];
    this.azureAppId = this.data.appClientId;
    this.callbackUrl = encodeURIComponent(this.localAppDataService.AuthConfig.callbackURL);
    this.requiredScopes = '.default';
    this.url = `https://login.microsoftonline.com/${this.tenantDomain}/v2.0/adminconsent?client_id=${this.azureAppId}&redirect_uri=${this.callbackUrl}&scope=${this.requiredScopes}`;
    this.translate
      .get('PermissionsRequiredMessage', {
        ahref_start: `<a href='${this.url}'><u>`,
        ahref_end: `</u></a>`,
      })
      .subscribe((PermissionsRequiredMessage: string) => {
        this.PermissionsRequiredMessage = PermissionsRequiredMessage;
      });
  }

  submit() {
    this.confirmDialogRef.close(1);
    window.location.href = this.url;
  }

  cancel() {
    this.confirmDialogRef.close(0);
  }
}
