import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
//import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { BnNgIdleService } from 'bn-ng-idle';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { BrowserStorage } from './browser-storage';
import { Auth0Config } from './Services/auth-env';
import { LogHandlerService } from './Services/log-handler.service';
import { SynappxEnvService } from './Services/synappx-env.service';
import { TenantService } from './Services/tenant.service';
import { TokenStorage } from './Services/token.storage';

type AuthRet = {err: any, authResult: any};

export class BaseAuth {

    protected _idToken: string;
    protected _accessToken: string;
    protected _expiresAt: number;
    protected _sub: string;
    protected _loggedUserDetails: any;
    protected connectionName: string;
    protected userEmailId: string;
    protected userUpnId: string;
    public applicationType;
    public loggedInUserName: string;
    public homeDomain: string;
    public sharpstartlogout: string;
    public calendarAccountStatus: string;
    public servicePreviouslyLoggedIn


    public loginSuccess: BehaviorSubject<AuthRet>;
    public loginFailure: BehaviorSubject<AuthRet>;
    public renewAccessToken: BehaviorSubject<AuthRet>;
    public beforeLogout: Subject<any>;

    public hasSharpServiceRole = 'yes';

    renewalTimer: any;
    userProfile: any;
    public get AuthConfig(): Auth0Config | undefined {
        return this.environment.AuthConfig;
    }

    requestedScopes = 'openid profile'; // Scopes :Auth0 -> API -> Scopes
    auth0: auth0.WebAuth;

    constructor(protected environment: SynappxEnvService,
        protected bnIdle: BnNgIdleService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected logHandlerService: LogHandlerService,
        //protected translate: TranslateService,
        protected browserStorage: BrowserStorage,
        protected tenantService: TenantService,
        protected token: TokenStorage,
    ) {
        this.auth0 = new auth0.WebAuth({
            clientID: this.environment.AuthConfig.clientID,
            domain: this.environment.AuthConfig.domain,
            responseType: 'token id_token',
            audience: this.environment.AuthConfig.apiUrl,
            redirectUri: this.environment.AuthConfig.callbackURL,
            scope: this.requestedScopes
        });
        this._idToken = '';
        this._accessToken = '';
        this._expiresAt = 0;
        this._sub = '';
        this.applicationType = '';
        // loginSuccess fires null by default. After login succeeds, it will fire again with login result
        this.loginSuccess = new BehaviorSubject<AuthRet>(null);
        // loginFailure fires null by default. After login succeeds, it will fire again with login result
        this.loginFailure = new BehaviorSubject<AuthRet>(null);
        // fire an event before logout
        this.beforeLogout = new Subject<any>();
        this.renewAccessToken = new BehaviorSubject<AuthRet>(null);
    }

    public get accessToken(): string {
        return this._accessToken;
    }

    public get auth0userId(): string {
        return this._sub;
    }

    public get idToken(): string {
        return this._idToken;
    }

    public get userEmail(): string {
        return this.userEmailId;
    }

    public get userUpn(): string {
        return this.userUpnId;
    }

    public set userUpn(userUpnId) {
        this.userUpnId = userUpnId;
    }

    public get loggedInUserDetails(): any {
        return this._loggedUserDetails;
    }

    public set loggedInUserDetails(val: any) {
        this._loggedUserDetails = val;
    }

    public get appType() {
        return this.applicationType;
    }

    public get calendarAccStatus(): string {
        return this.calendarAccountStatus;
    }

    public get getHomeDomain(): string {
        return this.homeDomain;
    }

    /**
     * Perform login using Auth0
     */
    public login(): void {
      console.log("login");
      const options : any = {
          // include User.ReadBasic.All scope to Eliminate Handoff to MS Global Admin SHPLT-6143
          ms_scope: "openid,profile,email,offline_access,User.Read,User.ReadBasic.All",
          gw_scope: this.environment.googleScopes.join()
        };
        console.log(options);
        // Check the logged out status When user tries to re-login.
        // If he is not logged out, display th application service page.
        // Otherwise add prompt to display the password page.
        const isLoggedOut = localStorage.getItem('isLoggedOut');
        if (isLoggedOut === 'true') {
            options['prompt'] = 'login';
            localStorage.removeItem('isLoggedOut');
        }
        // '?prompt=admin_consent' will throw an error as the admin_consent is invalid
        // for microsoft v2 authorize endpoint
        if (window.location.search && window.location.search.toString().indexOf('?admin_consent=SCP') >= 0) {
            options['prompt'] = 'consent';
            options['ms_scope'] = options['ms_scope'] + "," + 'Group.Read.All,Directory.Read.All';
        }
        this.auth0.authorize(options);
    }

    /**
     * Check if the application is currently logged in with a valid user account (and also login session not expired)
     */
    public get isLoggedIn() {
        return this.isAuthenticated();
    }

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
              console.log('Fire the success failure event');
                // const calendar_acc_info = authResult.idTokenPayload['calendar_acc_info'] || {};
                localStorage.removeItem('loginRetries');
                this.connectionName = authResult.idTokenPayload['https://marvel.sharp.com/connection'];
                // email id of the current logged in user in ssp
                this.userEmailId = authResult.idTokenPayload['https://marvel.sharp.com/sspuser'] || authResult.idTokenPayload['https://marvel.sharp.com/email'] ;
                // UPN of the user logged in
                this.userUpn = authResult.idTokenPayload['https://marvel.sharp.com/email'];
                this.calendarAccountStatus = authResult.idTokenPayload.calendarAccountStatus;
                // Application type selection based on access token sub
                this.applicationType = authResult.idTokenPayload['sub'].split('|')[0];
                this.homeDomain = authResult.idTokenPayload["https://marvel.sharp.com/homeDomain"];
                this.sharpstartlogout = authResult.idTokenPayload["sharpstartlogout"];
                // Allow Guest Admin Login with existing Sign-In selection
                this.hasSharpServiceRole = authResult.idTokenPayload["https://marvel.sharp.com/sharpStartServiceRole"] || "no";

                // Fire the login success event
                this.loginSuccess.next({err, authResult});
            } else if(err) {
              console.log('Fire the login failure event');
                // Fire the login failure event
                this.loginFailure.next({ err, authResult });
            }
        });
    }

    public localLogin(authResult): void {
        // Set isLoggedIn flag in localStorage
        this.browserStorage.setLoggedInStatus(true);
        // localStorage.setItem('isLoggedIn', 'true');
        // Set the time that the access token will expire at
        const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        this._accessToken = authResult.accessToken;
        this._idToken = authResult.idToken;
        this._sub = authResult.idTokenPayload.sub;
        // PENDING: this.viewManagementService.adminType = authResult.idTokenPayload['https://marvel.sharp.com/adminType'];
        // PENDING: this.viewManagementService.rmmAdminType = this.rmmAdminRole;
        this._expiresAt = expiresAt;
        // Renewal will happen 30 seconds before expiry.
        // But two successive renewals need minimum 60 second gap
        const renewalTime = Math.max(60, authResult.expiresIn - 30);
        // console.log(`Token will expire in ${authResult.expiresIn} second(s), will renew in ${renewalTime} second(s)`);
        // console.log(authResult.accessToken);
        this.renewalTimer = timer(renewalTime * 1000).subscribe(() => {
            this.renewTokens();
        });
    }

    /**
     * Renew the Auth0 token at the scheduled time
     */
    public renewTokens() {
        if (this.renewalTimer) {
            this.renewalTimer.unsubscribe();
        } else {
            console.error('renewal Timer is invalid');
            return;
        }
        this.auth0.checkSession({}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                console.log(new Date().getTime());
                this.localLogin(authResult);
                this.renewAccessToken.next({err,authResult});
            } else if (err) {
                console.error(`Could not get a new token (${err.error}: ${err.error_description}).`);
                this.logout();
            }
        });
    }

    public logoutONMaintenanceMode(): void {
        localStorage.setItem('isLoggedOut', 'true');
        const logoutParams = {
            returnTo: this.applicationType === 'google-apps' ? `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${this.environment.AuthConfig.callbackURL}` : location.origin,
            client_id: this.environment.AuthConfig.clientID
        }
        if (this.environment.stackRegion == 'US' && ['sharp-start'].includes(this.homeDomain)) {
            logoutParams.returnTo = `${this.environment.sharpstart_logout_url}?${this.sharpstartlogout}`;
        }
        this.auth0.logout(logoutParams);
        this.token.setLoggedInStatus(false);
        this.token.removeSession('currentUrl');
    }

    public logout(): void {
        // Call the before logout event handler
        this.beforeLogout.next(null);
        // Go back to the home route
        // Added the key in local storage to confirm the user is logged out from application
        localStorage.setItem('isLoggedOut', 'true');
        const logoutParams = {
            returnTo: this.applicationType === 'google-apps' ? `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${this.environment.AuthConfig.callbackURL}` : location.origin,
            client_id: this.environment.AuthConfig.clientID
        }
        if (this.environment.stackRegion == 'US' && !['waad', 'google-apps', 'auth0'].includes(this.applicationType)) {
            logoutParams.returnTo = `${this.environment.sharpstart_logout_url}?${this.sharpstartlogout}`;
        }
        console.log(logoutParams);
        this.auth0.logout(logoutParams);
        this.browserStorage.setLoggedInStatus(false);
        this.browserStorage.removeSession('currentUrl');
        //Here Remove the localStorage for suspendedlistpoup show/Hide Features
        localStorage.removeItem('agentSuspendedList');
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        return new Date().getTime() < this._expiresAt;
    }

    public getProfile(cb): void {
        if (!this._accessToken) {
            throw new Error('Access token must exist to fetch profile');
        }

        const self = this;
        this.auth0.client.userInfo(this._accessToken, (err, profile) => {
            if (profile) {
                self.userProfile = profile;
            }
            cb(err, profile);
        });
    }
    public get getConnectionName(): string {
        return this.connectionName;
    }

    get UserName(): string {
        return this.loggedInUserName;
    }

    public idleTimeUser(seconds): void {
        this.bnIdle.startWatching(seconds).subscribe((res) => {
            if (res) {
                console.log('session expired');
                this.logout();
            }
        });
    }
}
