import { Injectable } from '@angular/core';
import { CloudPrintEnvService } from '../cloud-print-env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalAppDataService } from '../local-app-data.service';

@Injectable({
    providedIn: 'root'
})
export class SspGenericService {

    constructor(protected envService: CloudPrintEnvService,
        protected http: HttpClient,
        protected localAppDataService: LocalAppDataService) {
    }

    /**
     * Gets the url for call SSP Common Tenant API
     */
    get DeletePreferencesApiUrl(): string {
        return this.envService.sspMarvelApi + '/subscriptions/';
    }

    /**
     * Gets the url for call SSP Common Tenant API
     */
    get SubscriptionApiUrl(): string {
        return this.envService.sspLicenseApi + '/subscription/details';
    }

    get SearchLogDataApiUrl():string {
        return this.envService.sspRoomsApi + '/elasticsearch';
    }

    get logstashUrl():string
    {
        return this.envService.sspRoomsApi + '/logstash';
    }

    get  exportlogUrl():string {
        return this.envService.sspRoomsApi + '/csv/so';
    }

    get deleteAgentDeviceURL():string{
        return this.envService.sspMarvelApi + '/device/'
    }

    get domainsAliasUrl(): string {
        return this.envService.sspLicenseApi + '/domainsAliasInfo';
    }

    get tenantInfoUrl(): string {
        return this.envService.sspLicenseApi + '/tenantInfo';
    }

    /**
     * Delete the notification preferences of any BA/BA user.
     * 
     * @param email The email id of the user
     * @returns 
     * 
     * @description This API should be called before removing a user entry from SSP DB
     */
    deletePreferences(email): Observable<any> {
        const subscriptionUrl = this.DeletePreferencesApiUrl + `user/${email}`;
        const httpOptions = {
            headers: new HttpHeaders({}),
        };
        return this.http.delete<any>(subscriptionUrl, httpOptions);
    }

    /**
     * Get the subscription details by calling SSP Common API
     * @param feature_type The feature name. Defaule value: scpbase. Valid values are: scpbase, room, users
     * @returns An observable that resolves to subscription details of given service
     * 
     */
    getSubscriptionDetails(feature_type: string = 'scpbase'): Observable<any> {
        const URI = this.SubscriptionApiUrl;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'feature_type': feature_type
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    searchLogData(schemaName, tenantId, startDate, endDate, sortField, sortOrder,
        pageSize, pageIndex, ESConditionField, ESConditionValues: string[], userName, categorySelected): Observable<any> {
        const URI = this.SearchLogDataApiUrl + '/' + schemaName + '/_search';
        const httpOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const searchBody = {
            "startDate": startDate,
            "endDate": endDate,
            "sortField": sortField,
            "sortOrder": sortOrder,
            "pageSize": pageSize,
            "pageIndex": pageIndex,
            "ESConditionField": ESConditionField,
            "ESConditionValues": ESConditionValues,
            "userName": '',
            "category": '',
            "logType": "logs"
        }
        if (userName) {
            searchBody.userName = userName;
        }
        if (categorySelected) {
            searchBody.category = categorySelected;
        }
        return this.http.post<any>(URI, searchBody, httpOptions);
    }

    getUniqueUsersList(schemaName, tenantId, startDate, endDate, serviceName): Observable<any> {
        const URI = this.SearchLogDataApiUrl + '/' + schemaName + '/_search';
        const httpOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const searchBody = {
            "startDate": startDate,
            "endDate": endDate,
            "serviceName": serviceName,
            "logType": "users"
        }
        return this.http.post<any>(URI, searchBody, httpOptions);
    }
    getUniqueListForViewFilter(schemaName, tenantId, startDate, endDate, application, userSelected, selectedCategory): Observable<any> {
        const URI = this.SearchLogDataApiUrl + '/' + schemaName + '/_search';
        const httpOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const searchBody = {
            "startDate": startDate,
            "endDate": endDate,
            "application": application,
            "logType": "usersandagents",
            "userName": '',
            "category": '',
        }
        if (userSelected) {
            searchBody.userName = userSelected;
        }
        if (selectedCategory) {
            searchBody.category = selectedCategory;
        }
        
        return this.http.post<any>(URI, searchBody, httpOptions);
    }
    /**
    * Retrieve usable and unusable domain alias information from SSP
    * @param domains domain aliases to filter
    *
    */
    getDomainsAliasInfo(domains): Observable<any> {
        const URI = this.domainsAliasUrl;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'domains': domains,
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    /**
    * Update the record for the tenant in DB
    * @param {tenant} tenant The tenant instance
    * @param {domainUpdate} domainUpdate For handling state of domain update
    */
    updateTenant(tenant: any, domainUpdate = 'no', featureType: string): Observable<any> {
        const URI = this.tenantInfoUrl;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.localAppDataService.TenantInfoAtLogin.accessToken,
                'domain-update': domainUpdate,
                'feature_type': featureType
            }),
        };
        return this.http.put<any>(URI, tenant, httpOptions);
    }
}
