<mat-sidenav-container autosize>
  <mat-sidenav mode="side" opened disableClose [ngClass]="{ 'is-expanded': !!isMenuExpanded }" (keydown.escape)="toggleMenu()">
    <button type="button" mat-icon-button class="scp_toggle_side_nav" (click)="toggleMenu()" matTooltip="{{ ( isMenuExpanded ? 'HideMenu' : 'ShowMenu' ) | translate }}">
      <mat-icon>{{ isMenuExpanded ? 'arrow_back_ios_new' : 'arrow_forward_ios_new' }}</mat-icon>
    </button>

    <mat-nav-list>
      <ng-container *ngTemplateOutlet="menuItemTemplate; context: { parentMenuItem: null, menuItems: menuList }"></ng-container>
    <ng-container 
        *ngTemplateOutlet="whatsNewTemplate; context: { parentMenuItem: null, whatsnewMenu: whatsnewMenu }"></ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #whatsNewTemplate let-parentMenuItem="parentMenuItem" let-whatsnew="whatsnewMenu">

    <mat-list-item class="bottom-item" *ngIf="whatsnewMenu.isVisible" [ngClass]="{ 'is-child': !!parentMenuItem }"
    [ngClass] = 'appropriateClass'
        matTooltip="{{ ( isMenuExpanded ? '' : whatsnewMenu.title ) | translate }}" matTooltipPosition="right">
        
<mat-icon matListItemIcon>{{ whatsnewMenu.icon }}</mat-icon>

<a matListItemTitle *ngIf="isMenuExpanded" [href]="'scp.whats_new.link_url' | translate" target="_blank"
    class="d-inline-flex" (click)="handleClick()">{{ whatsnewMenu.title | translate }}
</a>
<div class="redicon" *ngIf="!isWhatsNewClickedByUser"></div>
</mat-list-item>
</ng-template>
<!-- NOTE: This template allows for multi level/deep sub menus -->
<ng-template #menuItemTemplate let-parentMenuItem="parentMenuItem" let-menuItems="menuItems">
  <ng-container *ngFor="let menuItem of menuItems">
    <mat-list-item
      *ngIf="menuItem.isVisible"
      [ngClass]="{ 'is-child': !!parentMenuItem }"
      routerLink="{{ menuItem.viewLink }}"
      routerLinkActive="{{ menuItem.children ? '' : 'router-link-active' }}"
      matTooltip="{{ ( isMenuExpanded ? '' : menuItem.title ) | translate }}"
      matTooltipPosition="right"
    >
      <mat-icon matListItemIcon>{{ menuItem.icon }}</mat-icon>

      <a matListItemTitle *ngIf="isMenuExpanded" routerLink="{{ menuItem.viewLink }}">{{ menuItem.title | translate }}</a>

      <button matListItemMeta mat-icon-button *ngIf="menuItem.children" (click)="toggleSubMenu($event, menuItem)">
        <mat-icon>{{ menuItem.isOpen ? 'arrow_drop_down' : 'arrow_right' }}</mat-icon>
      </button>
    </mat-list-item>

    <div *ngIf="menuItem.children" [ngClass]="{ 'd-none': !menuItem.isOpen, 'is-child': !!parentMenuItem }">
      <ng-container *ngTemplateOutlet="menuItemTemplate; context: { parentMenuItem: menuItem, menuItems: menuItem.children }"></ng-container>
    </div>
  </ng-container>
</ng-template>
