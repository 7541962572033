import dayjs  from 'dayjs';
import { Injectable } from '@angular/core';
import { LocalAppDataService } from './local-app-data.service';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const dmyFormat2 = 'd/M/yyyy';
const ymdFormat = 'yyyy-M-d';
const mdyFormat = 'M/d/yyyy';
const ddmmyFormat = 'dd.mm.yyyy';
const ddmmyFormat1 = 'dd/MM/yyyy'


const dateFormats = {
    'en': mdyFormat,
    'da': ddmmyFormat,
    'de': ddmmyFormat,
    'fi': ddmmyFormat,
    'nb': ddmmyFormat,
    'pl': ddmmyFormat,
    'es': ddmmyFormat1,
    'fr': ddmmyFormat1,
    'it': ddmmyFormat1,
    'nl': ddmmyFormat,
    'sv': ymdFormat,
    'en-GB': dmyFormat2,
};

@Injectable({
    providedIn: 'root',
})
export class DateFormatService {
    private language24Format = true;
    private lang = 'en';
    private dateFormat = mdyFormat;

    constructor(
        private appDataService:LocalAppDataService) {
        this.lang = this.appDataService.getLanguage();
        this.dateFormat = dateFormats[this.lang].toUpperCase();
        if (this.lang !== 'en' && this.lang !== 'en-GB') {
            this.language24Format = true
        } else {
            this.language24Format = false
        }
    }
    
    
    /**
    * @param date parseDateLocale takes date and locale code ex: 'es' as input,
    * and formats to the required date format for subscription page
    */
    parseDateSubscription(date: string) {
        const formatedDate = dayjs(date).format(this.dateFormat);
        return formatedDate;
    }
    
    getDateFromTimeStamp(timestamp) {
        let d = new Date(timestamp);
        let date = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        switch (this.dateFormat) {
            case 'D.M.YYYY':
                return `${date}.${month}.${year}`
            case 'D/M/YYYY':
                return `${date}/${month}/${year}`
            case 'D-M-YYYY':
                return `${date}-${month}-${year}`
            case 'YYYY-M-D':
                return `${year}-${month}-${date}`
            case 'M/D/YYYY':
                return `${month}/${date}/${year}`
            case 'DD.MM.YYYY':
                return `${String(date).padStart(2, "0")}.${String(month).padStart(2, "0")}.${year}`
            case 'DD/MM/YYYY':
                return `${String(date).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`
        }
    }

    /**
   *
   * @param date formatDateTimeLocale takes date and locale code ex: 'es' as input,
   * and formats to the required date_time format for Audit log UI table
   * @param date Long Date
   * @param locale browser locale
   * @param locale
   */
    formatDateTimeLocale(date: Date) {
        if (this.lang == 'en' || this.lang == 'en-GB') {
            const englishDateTimeFormat = dayjs(date).format(this.dateFormat + ' ' + 'h:mm A');
            return englishDateTimeFormat;
        }
        // Display date time in 24 hr format for 'da', 'de', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv' Language
        const formatedDateTime = dayjs(date).format(this.dateFormat + ' ' + 'HH:mm');
        return formatedDateTime.toString();
    }

     /**
     * @param date parseDateLocale takes date and locale code ex: 'es' as input,
     * and formats to the required date format for graph and export
     * url param values
     * @param date calendar date selected
     * @param locale
     */
    parseDateLocale(date: string) {
        const formatedDate = dayjs(date, this.dateFormat).format('YYYY-MM-DD');
        return formatedDate;
    }
   
}
