import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { ScpCustomMatPaginatorIntl } from '../../lib/scp-custom-mat-paginator-intl';
import { ErrorComponent } from '../shared/components/error/error.component';
import { OfflineComponent } from '../shared/components/offline/offline.component';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { SessionExpiredComponent } from '../shared/components/session-expired/session-expired.component';
import { UnAuthorizedComponent } from '../shared/components/un-authorized/un-authorized.component';
import { ExportDialogComponent } from '../shared/dialogs/export.dialog/export.dialog.component';
import { NotificationDialogComponent } from '../shared/dialogs/notification-dialog/notification-dialog.component';
import { NotificationMessagesComponent } from '../shared/dialogs/notification-messages/notification-messages.component';
import { LocalAppDataService } from '../shared/services/local-app-data.service';
import { SCPTranslateLoaderFactory } from '../shared/services/scp-resource-loader';
import { APP_DATE_FORMATS, AppDateAdapter } from '../shared/services/ssp/format-datepicker';
import { ManageDeviceService } from '../shared/services/ssp/manage-device.service';
import { NotificationService } from '../shared/services/ssp/notification.service';
import { SharedModule } from '../shared/shared.module';
import { AdminLogComponent } from './admin-log/admin-log.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AddAdminComponent } from './admin/dialogs/add-admin/add-admin.component';
import { AdminConfirmComponent } from './admin/dialogs/admin-confirm/admin-confirm.component';
import { ConfirmDeleteComponent } from './admin/dialogs/confirm-delete/confirm-delete.component';
import { SelectServicesComponent } from './admin/dialogs/select-services/select-services.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SetupGuideComponent } from './dashboard/setup-guide/setup-guide.component';
import { DepartmentUpsertComponent } from './departments/department-upsert/department-upsert.component';
import { DepartmentsComponent } from './departments/departments.component';
import { DeviceMFPSearchComponent } from './devices/device-mfp-search/device-mfp-search.component';
import { DeviceUpsertComponent } from './devices/device-upsert/device-upsert.component';
import { DevicesComponent } from './devices/devices.component';
import { JacloudLayoutComponent } from './jacloud-layout/jacloud-layout.component';
import { JobsHistoryComponent } from './jobs/jobs-history/jobs-history.component';
import { JobsQueueComponent } from './jobs/jobs-queue/jobs-queue.component';
import { JobsComponent } from './jobs/jobs.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { ReportsExecuteComponent } from './reports/reports-execute/reports-execute.component';
import { ReportsListUpsertComponent } from './reports/reports-list-upsert/reports-list-upsert.component';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { ReportsScheduleUpsertComponent } from './reports/reports-schedule-upsert/reports-schedule-upsert.component';
import { ReportsScheduleComponent } from './reports/reports-schedule/reports-schedule.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsLoginComponent } from './settings/settings-login/settings-login.component';
import { SettingsPinReplacementComponent } from './settings/settings-pin-replacement/settings-pin-replacement.component';
import { SettingsScheduleComponent } from './settings/settings-schedule/settings-schedule.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupportedDomainsComponent } from './supported-domains/supported-domains.component';
import { SystemLogComponent } from './system-log/system-log.component';
import { TenantComponent } from './tenant/tenant.component';
import { ImportUsersComponent } from './users/import-users/import-users.component';
import { UsersComponent } from './users/users.component';
import { AdminApprovalComponent } from './admin/admin-approval/admin-approval.component';
import { NoticeComponent } from '../scp-shared/dialogs/notice/notice.component';
import { GuestPrintAdminApprovalComponent } from './settings/admin-approval/guestprint-admin-approval.component';

import { AbsoluteValuePipe } from '../scp-shared/pipe/absolutevalue.pipe';
import { GrantPermissionsComponent } from './admin/grant-permissions/grant-permissions.component';
import { WarningActionDialogComponent } from '../scp-shared/dialogs/warning-action-dialog/warning-action-dialog.component';

@NgModule({
  declarations: [
    JacloudLayoutComponent,
    DashboardComponent,
    SetupGuideComponent,
    UsersComponent,
    ImportUsersComponent,
    DepartmentsComponent,
    DepartmentUpsertComponent,
    DevicesComponent,
    DeviceUpsertComponent,
    DeviceMFPSearchComponent,
    JobsComponent,
    JobsQueueComponent,
    JobsHistoryComponent,
    ReportsListComponent,
    ReportsListUpsertComponent,
    ReportsExecuteComponent,
    ReportsScheduleComponent,
    ReportsScheduleUpsertComponent,
    ReportsComponent,
    SettingsComponent,
    SettingsLoginComponent,
    SettingsScheduleComponent,
    SettingsPinReplacementComponent,
    SubscriptionsComponent,
    SupportedDomainsComponent,

    ErrorComponent,
    OfflineComponent,
    UnAuthorizedComponent,
    PageNotFoundComponent,
    SessionExpiredComponent,
    NoticeComponent,
    WarningActionDialogComponent,

    AdminUsersComponent,
    AddAdminComponent,
    AdminConfirmComponent,
    ConfirmDeleteComponent,
    NotificationMessagesComponent,
    NotificationDialogComponent,
    SelectServicesComponent,
    AdminLogComponent,
    ExportDialogComponent,
    SystemLogComponent,
    TenantComponent,
    AdminApprovalComponent,
    GrantPermissionsComponent,

    AbsoluteValuePipe,
    GuestPrintAdminApprovalComponent,
  ],
  imports: [
    SharedModule,
    LayoutRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: SCPTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: Window, useValue: window }, // provide browser's window ref
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above' } },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MatPaginatorIntl, useClass: ScpCustomMatPaginatorIntl },
    NotificationService,
    ManageDeviceService,
  ],
})
export class LayoutModule {
  constructor(private translate: TranslateService, private appDataService: LocalAppDataService) {
    const appLang = this.appDataService.browserLang();

    this.translate.use(appLang);
  }
}
