import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base.component';
import { SCPRouterLinks } from '../../shared/models/router-model';
import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { IApplicationVersion, ITenantUser } from '../../shared/models/scp-model';

export interface IMenuItem {
  title: string;
  viewLink?: SCPRouterLinks | string;
  icon: string;
  isVisible: boolean;
  children?: IMenuItem[];

  isOpen?: boolean;
}

@Component({
  selector: 'lib-jacloud-layout',
  templateUrl: './jacloud-layout.component.html',
  styleUrls: ['./jacloud-layout.component.scss'],
})
export class JacloudLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.scp_theme') applySCPThemeClass = true;

  isMenuExpanded = true;
  menuList: IMenuItem[] = [];
  whatsnewMenu: IMenuItem;
  isWhatsNewClickedByUser: boolean = false;
  versionNumber: any;
  dbVersion: string;
  updatedVersion = false;
    appropriateClass: string = '';

    @HostListener('window:resize', ['$event'])
    getScreenHeight(event?) {
        //console.log(window.innerHeight);
        if (window.innerHeight <= 650) {
            this.appropriateClass = 'bottomRelative';
        } else {
            this.appropriateClass = 'bottomStick';
        }
    }
    constructor(private router: Router) {
    super();
    this.getScreenHeight();
    const isAdmin = this.appService.isSCPAdminUser;

    this.menuList = [
      { title: 'scp.common.dashboard', viewLink: SCPRouterLinks.dashboard, icon: 'space_dashboard', isVisible: isAdmin },
      { title: 'scp.common.users', viewLink: SCPRouterLinks.users, icon: 'supervised_user_circle', isVisible: isAdmin },
      // { title: 'scp.common.departments', viewLink: SCPRouterLinks.departments, icon: 'business', isVisible: isAdmin },
      { title: 'scp.common.devices', viewLink: SCPRouterLinks.devices, icon: 'print', isVisible: isAdmin },
      { title: 'scp.common.jobs', viewLink: SCPRouterLinks.jobs, icon: 'work', isVisible: true }, // Visible for ALL users
      { title: 'scp.common.reports', viewLink: SCPRouterLinks.reports, icon: 'assessment', isVisible: isAdmin },
      {
        title: 'scp.common.settings',
        viewLink: SCPRouterLinks.security,
        icon: 'settings',
        isVisible: true,
        children: [
          { title: 'scp.common.security', viewLink: SCPRouterLinks.security, icon: 'lock', isVisible: true },
          { title: 'scp.common.adminusers', viewLink: SCPRouterLinks.adminUsers, icon: 'supervised_user_circle', isVisible: isAdmin },
          { title: 'scp.common.tenant', viewLink: SCPRouterLinks.tenant, icon: 'apartment', isVisible: isAdmin },
          //hide the domains page for SCP based on SHPLT-7880 ticket
        //   { title: 'scp.common.supporteddomains', viewLink: SCPRouterLinks.supportedDomains, icon: 'dns', isVisible: isAdmin },
        ],
      },
      { title: 'scp.common.subscriptions', viewLink: SCPRouterLinks.subscriptions, icon: 'subscriptions', isVisible: isAdmin },
      {
        title: 'scp.common.system',
        viewLink: SCPRouterLinks.adminLogs,
        icon: 'system_update_alt',
        isVisible: isAdmin,
        children: [
          { title: 'scp.common.adminlog', viewLink: SCPRouterLinks.adminLogs, icon: 'dvr', isVisible: isAdmin },
          { title: 'scp.common.systemlog', viewLink: SCPRouterLinks.systemLogs, icon: 'dvr', isVisible: isAdmin },
        ],
      },
    ];
    this.whatsnewMenu = { title: 'scp.common.whatsnew', icon: 'new_releases', isVisible: true }; // Visible for ALL users
    this.addOrRemoveMainContainerID();
    this.updateMenuItems(this.menuList);
  }
    
  ngOnInit() {
      this.versionNumber = document.getElementById('versionRef').innerHTML;
      var splitStr = this.versionNumber.split(" ")[1];
      this.appService.getApplicationVersion().pipe(this.takeUntilDestroyed()).subscribe(
          (res) => {
            if (res.body != null && res.body.version != null) {
               this.dbVersion = res.body.version;
                  this.appService.getUser(this.appService.userDetails.guid).pipe(this.takeUntilDestroyed())
                      .subscribe((data: ITenantUser) => {
                          if (!data.whatsNewFlag) {
                              this.updatedVersion = true;
                              this.isWhatsNewClickedByUser = false;
                              if (!(this.dbVersion == splitStr)) {
                                  this.updateVersion(splitStr);
                                  this.updateUsers();
                              }
                              
                          } else {
                              if (!(this.dbVersion == splitStr)) {
                                  this.updateUsersCallService(splitStr);
                              } else {
                                  this.updatedVersion = false;
                                  this.isWhatsNewClickedByUser = true;
                              }
                          }
                      });
                
            } else {
                this.dbVersion = null;
                this.updateUsersCallService(splitStr);
            }
           
        },
        (error: HttpErrorResponse) => {
            this.appService.showError(this.translate.instant('scp.whats_new.upsert.save_error_message'));
        }
      );
   this.router.events
      .pipe(
        this.takeUntilDestroyed(),
        filter((evt) => evt instanceof NavigationEnd)
      )
      .subscribe(() => this.updateMenuItems(this.menuList));
  }
  updateUsers() {
      this.appService.updateUsers().pipe().subscribe((resp) => {
          if (resp.status == 204) {
              // do nothing
          }
      },
          (err: HttpErrorResponse) => {
              this.appService.showError(this.translate.instant('scp.users.upsert.save_error_message'));

          });
  }
    updateUsersCallService(splitStr: string) {
      this.appService.updateUsers().pipe().subscribe((resp) => {
          if (resp.status == 204) {
              // do nothing
          }
          this.verifyAndUpdateForApplicationVersion(splitStr);
      },
          (err: HttpErrorResponse) => {
              this.appService.showError(this.translate.instant('scp.users.upsert.save_error_message'));

          });
  }

    updateVersion(splitStr : string) {
      this.appService.updateApplicationVersion({
          version: splitStr,
      }).pipe().subscribe(
          (res) => {
              // do nothing
          }, (error: HttpErrorResponse) => {
              this.appService.showError(this.translate.instant('scp.whats_new.upsert.save_error_message'));
          }
      );
  }

  // NOTE: This recursive logic allows for multi level/deep sub menus
  updateMenuItems(menuItems: IMenuItem[]) {
    for (const menuItem of menuItems.values()) {
      if (menuItem.children) {
        menuItem.isOpen = JSON.stringify(menuItem.children).includes(this.router.url);

        this.updateMenuItems(menuItem.children);
      }
    }
  }

  toggleMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }

  toggleSubMenu($evt: Event, menuItem) {
    $evt.stopPropagation();

    menuItem.isOpen = !menuItem.isOpen;
  }

  // HACK: For Firefox/Safari left hand menu issue
  addOrRemoveMainContainerID(shouldRemoveId = false) {
    const el = document.querySelector('section.main-container');

    if (shouldRemoveId) {
      return el.removeAttribute('id');
    }

    return el.setAttribute('id', 'scp_main_container');
  }

  ngOnDestroy() {
    this.addOrRemoveMainContainerID(true);

    super.ngOnDestroy();
  }

    handleClick(): void {
        if (this.updatedVersion && !this.isWhatsNewClickedByUser) {
            this.appService.updateUser(this.appService.userDetails.guid, {
                whatsNewFlag: true,
            })
                .pipe(this.takeUntilDestroyed())
                .subscribe(
                    () => {
                        this.isWhatsNewClickedByUser = true;
                    },
                    (error : HttpErrorResponse) => {
                        this.appService.showError(this.translate.instant('scp.users.upsert.save_error_message'));
                    }
                );
        } else {
            // do nothing
        }
    }
    verifyAndUpdateForApplicationVersion(versionNumber: string) {
        this.appService.getApplicationVersion().pipe(this.takeUntilDestroyed()).subscribe(
            (res) => {
                if (res.body.version == null) {
                    this.appService.addApplicationVersion({
                        version: versionNumber,
                    }).pipe(this.takeUntilDestroyed())
                        .subscribe(
                            (response) => {
                                this.updatedVersion = true;
                                this.isWhatsNewClickedByUser = false;
                            },
                            (error: HttpErrorResponse) => {
                                this.appService.showError(this.translate.instant('scp.whats_new.upsert.save_error_message'));
                            }
                        );
                } else {
                    this.appService.updateApplicationVersion({
                        version: versionNumber,
                    }).pipe(this.takeUntilDestroyed())
                        .subscribe(
                            (res) => {
                                if (res.status == 200) {
                                    this.updatedVersion = false;
                                    this.isWhatsNewClickedByUser = true;
                                } else if (res.status == 204) {
                                    this.updatedVersion = true;
                                    this.appService.getUser(this.appService.userDetails.guid).pipe(this.takeUntilDestroyed())
                                        .subscribe(
                                            (data: ITenantUser) => {
                                                if (!data.whatsNewFlag) {
                                                    this.updatedVersion = true;
                                                    this.isWhatsNewClickedByUser = data.whatsNewFlag;
                                                }
                                                // do nothing
                                            }
                                        );
                                }
                            },
                            (err: HttpErrorResponse) => {
                                this.updatedVersion = false;
                                this.appService.showError(this.translate.instant('scp.whats_new.upsert.save_error_message'));
                            }
                        );
                }
            }
        );

    }
}
