import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorage } from '../../core/token.storage';
import { environment } from '../../../environments/environment';
import { TenantService } from '../services/tenant.service';
import { TranslateService } from '@ngx-translate/core';
import { LogHandlerService } from './log-handler.service';
import { ViewManagementService } from './view-management.service';
import { ITier } from '../interfaces/tenant';
import { MygroupsService } from '../../layout/rmm/services/mygroups/mygroups.service';
import { AdminAuthService } from 'auth-n';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmOperationComponent } from '../../layout/components/dialogs/confirm-operation/confirm-operation.component';
import { ADMIN_ROLES } from '../constant/constant';
import { RmmValidateUserService } from './rmm-validate-user.service';
export type AuthRet = { err: any, authResult: any };

@Injectable()
export class AuthService {

    public isComboLicense: boolean;
    public isMarvelExists: boolean;
    public isRmpExists: boolean;
    public isRMMExists: boolean;
    public onlyScpLicense: boolean;
    public tier: ITier;
    public tenantInfo: any;
    public tenantDomain: any;
    public viewRmm: boolean;
    public viewGo: boolean;
    public viewMeeting: boolean;
    public viewScp: boolean;
    serviceName: string;
    tenantTier: string;
    userType: string;
    adminRole: string;
    rmmAdminRole: string;
    cpUserType: string;
    cpUserRole: string;
    googleAllScopes: boolean = true;
    sharpstartlogout: string;
    public allowedRegularRmmAdmins = ['Primary Admin', 'IT Main', 'IT Helpdesk'];
    public allowedRmmGuestAdminsList = ['Service Main', 'Service Support', 'Service View Only'];
    public calendarAccList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public baDetailsAvailable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private _calendarResourceIds = [];
    public isCalendarAccountAdded = false;
    public firstTimeLogin: boolean = false;
    defaultDialogConfig = new MatDialogConfig();
    @Output() myEvent: EventEmitter<any> = new EventEmitter<any>();
    public isCloudPrint: boolean = true;
    public renewIdpToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private http: HttpClient,
        private router: Router,
        private logHandlerService: LogHandlerService,
        private token: TokenStorage,
        private tenantService: TenantService,
        private translate: TranslateService,
        private viewManagementService: ViewManagementService,
        private groupService: MygroupsService,
        private authn: AdminAuthService,
        public dialog: MatDialog,
        public rmmValidate: RmmValidateUserService
    ) {
        this.tenantInfo = {};
        this.tenantService = new TenantService(this.http, this.token);

        // use this.onLoginSuccess to handle login succes scenario
        this.authn.loginSuccess.subscribe({
            next: (authRet: AuthRet) => {
                if (authRet) {
                    const { err, authResult } = authRet;
                    this.onLoginSuccess(err, authResult);
                }
            }
        });
        // use this.onLoginSuccess to handle login succes scenario
        this.authn.loginFailure.subscribe({
            next: (authRet: AuthRet) => {
                if (authRet) {
                    const { err, authResult } = authRet;
                    this.onLoginFailure(err, authResult);
                }
            }
        });
        // use this.beforeLogout to handle the beforeLogout notification
        this.authn.beforeLogout.subscribe({
            next: (ret: any) => {
                window.sessionStorage.removeItem('scp-dashboard-page-visited');
                this.beforeLogout(ret);
            }
        })
    }

    get calendarResourceIds(): any {
        return this._calendarResourceIds;
    }


    set calendarResourceIds(resourceIds) {
        this._calendarResourceIds = resourceIds;
    }

    get hasSharpServiceRole(): string {
        return this.authn.hasSharpServiceRole;
    }

    get loggedInUserDetails(): any {
        return this.authn.loggedInUserDetails;
    }

    get loginSuccess(): any {
        return this.authn.loginSuccess;
    }

    get renewAccessToken(): any {
        return this.authn.renewAccessToken;
    }

    handleAuthentication() {
        return this.authn.handleAuthentication();
    }

    login() {
        this.authn.login();
    }

    isAuthenticated() {
        return this.authn.isAuthenticated();
    }

    get isLoggedIn() {
        return this.authn.isLoggedIn;
    }

    get userEmail() {
        return this.authn.userEmail;
    }

    public get accessToken(): string {
        return this.authn.accessToken || '';
    }

    get applicationType() {
        return this.authn.appType;
    }

    logoutONMaintenanceMode() {
        this.authn.logoutONMaintenanceMode();
    }

    get userUpn() {
        return this.authn.userUpn;
    }

    get auth0userId(): string {
        return this.authn.auth0userId;
    }

    get calendarAccStatus() {
        return this.authn.calendarAccStatus;
    }

    public get getConnectionName(): string {
        return this.authn.getConnectionName;
    }

    get UserName(): string {
        return this.authn.UserName;
    }

    get homeDomain(): string {
        return this.authn.getHomeDomain;
    }

    /**
     * This method handles the login success event fired by the auth-n library
     * @param err This will be non-null if error happened during login process
     * @param authResult This will be valid object, if login is success. This object
     * contains entries like access token, id token, claims inside id token, etc.
     *
     * @description This method will set service based properties and flags so these
     * properties/flags can be used by other services/components to enable/disable/hide features.
     *
     * This method also validates if the logged-in user is a valid administrator. If not, an error
     * message will be shown and the user will be logged out.
     */
    onLoginSuccess(err: any, authResult: any) {
        if ((environment.AuthConfig?.callbackURL || '').startsWith('http://localhost')) {
            console.log(JSON.stringify(authResult, null, 4));
        }
        // Handle the calendar account login first
        this.handleCalendarAccountLogin(authResult);
        this.userType = authResult.idTokenPayload['https://marvel.sharp.com/role'].toLowerCase();
        const rmmInfo = authResult.idTokenPayload['https://marvel.sharp.com/rmmInfo'] || {};
        this.rmmAdminRole = rmmInfo.role; // make default value as N/A when auth0 part is done
        let cpInfo = authResult.idTokenPayload['https://marvel.sharp.com/cpInfo'] || {};
        this.cpUserType = cpInfo.userType;
        this.cpUserRole = cpInfo.role;
        this.adminRole = authResult.idTokenPayload['https://marvel.sharp.com/adminType'];
        if (this.isValidAdministrator(authResult)) {
            this.authn.localLogin(authResult);
            this.authn.idleTimeUser(authResult.expiresIn);
            this.viewManagementService.adminType = authResult.idTokenPayload['https://marvel.sharp.com/adminType'];
            this.viewManagementService.rmmAdminType = this.rmmAdminRole;
            this.authn.loggedInUserName = authResult.idTokenPayload['https://marvel.sharp.com/name'];
            // Assume EULA is not accepted
            this.token.setEulaAccepted(false);
            // Assume Cancellation Notification is not accepted for both user and room
            this.token.setRoomCancellationAccepted(false);
            this.token.setUserCancellationAccepted(false);
            this.getLicenseStatus(authResult.accessToken);
        } else {
            this.showInvalidAdminWarning(authResult);
        }
    }

    /**
     * Show the warning message that currently logged in user is not a valid administrator
     * and then take the user back to login page
     * @param authResult The authn result from auth0 sdk
     */
    public showInvalidAdminWarning(authResult: any) {
        // TODO: implement localization in test app and then uncomment this part to show warning
        this.translate.getTranslation(this.getBrowserLanguage()).subscribe(() => {
            let warningMsg = 'NotValidAdministrator';
            // Display different waring message when Sharp-Start user does not have enough role.
            if (environment.stackRegion == "US" && this.authn.homeDomain == 'sharp-start' && this.allowedRmmGuestAdminsList.includes(this.rmmAdminRole) && this.hasSharpServiceRole === 'no') {
                warningMsg = 'SharpStartRoleWarning';
            }
            alert(this.translate.instant(warningMsg));
        });
        // get the accesss token & user_name for failed 'Login' entry into logstash
        this.authn.localLogin(authResult);
        const loggedInUser = authResult.idTokenPayload['https://marvel.sharp.com/name'];
        const emailId = authResult.idTokenPayload['https://marvel.sharp.com/email'];
        this.logHandlerService.tenantId = this.getConnectionName;
        this.logHandlerService.addSystemLog('', '', 'Not Valid Administrator', '', '', 'Login', 'Login', loggedInUser, emailId, 'Unknown', 'MST20033');
        this.logout();
    }

    /**
    * Checks if the currently logged in user is a valid administrator based on given authn Result
    * @param authResult The authResult
    * @returns true if the user is a valid administrator. false otherwise
    */
    protected isValidAdministrator(authResult: any): boolean {
        // Allow Guest Admin Login with existing Sign-In selection
        this.authn.hasSharpServiceRole = "yes";
        if (environment.stackRegion == "US" && this.authn.homeDomain == 'sharp-start') {
            this.authn.hasSharpServiceRole = authResult.idTokenPayload["https://marvel.sharp.com/sharpStartServiceRole"] || "no";
        }
        let validAdmin = false;
        validAdmin = ADMIN_ROLES.GO_ADMIN_ROLES.includes(this.adminRole) || this.allowedRegularRmmAdmins.includes(this.rmmAdminRole);
        validAdmin = validAdmin || (this.allowedRmmGuestAdminsList.includes(this.rmmAdminRole) && this.hasSharpServiceRole === 'yes');
        validAdmin = validAdmin || (['tenant_admin', 'tenant_user'].includes(this.cpUserType));
        return validAdmin;
    }

    onLoginFailure(err: any, authResult: any) {
        console.log(`authResult: ${authResult}`);
        err = err || { errorDescription: 'n/a' };
        console.warn(err);
        if (err.errorDescription.includes("Email domain mismatch:")) {
            this.translate.getTranslation(this.getBrowserLanguage()).subscribe(() => {
                this.defaultDialogConfig.data = {
                    title: [this.translate.instant('DomainMismatchTitle')],
                    message: (this.translate.instant('DomainMismatchMessage')),
                    alertMode: true
                };
                const confirmDialogRef = this.dialog.open(ConfirmOperationComponent, this.defaultDialogConfig);
                confirmDialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.handleLoginFailure(err);
                    }
                });
            });
        } else {
            this.handleLoginFailure(err);
        }
    }

    /**
     * Just before logout, we need to log the user who is loggout
     * @param ret
     */
    beforeLogout(ret: any) {
        if (ADMIN_ROLES.GO_ADMIN_TYPES.includes(this.userType.toLowerCase()) || ADMIN_ROLES.RMM_ADMIN_ROLES.includes(this.rmmAdminRole) || this.cpUserType == "tenant_user" || this.cpUserType == "tenant_admin") {
            this.logHandlerService.addAdminLog('Logout', 'Logout', 'MAA00002', 'Logout', this.serviceName);
        }
    }

    /**
     * Handle the calendar account login scenario
     * If the auth0 login performed is for adding a GW calendar account, then the
     * admin portal should display a 'registration success' message and then logout.
     * No other admin portal should be displayed for this scenario.
     * This method takes care of this part of requirement.
     * @param authResult The authentication result.
     * The calendar registration details is exracted from the authResult
     * @returns none
     */
    handleCalendarAccountLogin(authResult: any) {
        const calendar_acc_info = authResult.idTokenPayload['calendar_acc_info'] || {};
        //  TODO: Calendar page navigation to be handled in loginSuccess event handler
        if (['register', 'change'].includes(calendar_acc_info.action_type)) {
            this.token.removeSession('currentUrl');
            this.router.navigate(['calendar-message']);
            return;
            // const loggedInUser = calendar_acc_info.adminName;
            // const emailId = calendar_acc_info.adminEmail;
            // this.logHandlerService.addSystemLog('', '', 'Calendar Account Registration', '', '', 'Calendar Account', 'Calendar Account', loggedInUser, emailId, 'Calendar Account', '');
        }
    }

    tenantAvailable?: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Get the license status and access level of currently logged-in user
     * based on the tenant and user information from backend database
     *
     * @param acessToken The auth0 access_token
     *
     * @description This method will also redirect to appropriate start-up page
     * for the current user. The criteria for startup page includes:
     *  valid license for service, admin access to service and last logged in service
     */
    public getLicenseStatus(acessToken): void {
        let loginserviceName = '';
        this.tenantService.getTenantInfo(acessToken).subscribe(data => {
            this.tenantInfo = data[0];
            this.tenantDomain = data[0].domain;
            this.tenantInfo.domainAliases = this.tenantInfo.domainAliases || [];
            this.tenantInfo.domain = this.tenantInfo.domain || this.tenantInfo.customerContactEmail.split('@')[1].toLowerCase();
            this.tier = this.tenantInfo.tier;
            this.tenantAvailable.next(this.tenantInfo);
            this.tenantService.queryUser({ email: this.authn.userEmail }, this.authn.accessToken).subscribe((res) => {
                this.authn.loggedInUserDetails = res[0];
                //Logged-in user details is not present in ssp
                let scpUserInfo;
                if (!this.authn.loggedInUserDetails) {
                    scpUserInfo = {
                        "userName": this.authn.UserName,
                        "email": this.authn.userEmail,
                    }
                }
                if (!this.authn.loggedInUserDetails?.servicePreviouslyLoggedIn) {
                    this.firstTimeLogin = true;
                }
                const tier = Object.assign({}, this.tenantInfo.tier);
                tier.rocketStart = false;
                tier.marvel = (this.tenantInfo.tier.marvel || this.tenantInfo.tier.rocketStart);

                if (this.tenantInfo.isRoomCancellationAccepted && (this.tenantInfo.isRoomCancellationAccepted === true)) {
                    this.token.setRoomCancellationAccepted(true);
                }
                if (this.tenantInfo.isUserCancellationAccepted && (this.tenantInfo.isUserCancellationAccepted === true)) {
                    this.token.setUserCancellationAccepted(true);
                }
                if (this.loggedInUserDetails && this.loggedInUserDetails?.servicePreviouslyLoggedIn) {
                    const previousService = this.loggedInUserDetails?.servicePreviouslyLoggedIn;
                    if (tier[previousService] === false) {
                        this.token.removeSession('currentUrl');
                    }
                }
                this.viewRmm = (this.tier.rmm && ADMIN_ROLES.RMM_ADMIN_ROLES.includes(this.rmmAdminRole));
                this.viewGo = (this.tier.marvel && ADMIN_ROLES.GO_ADMIN_ROLES.includes(this.adminRole));
                this.viewMeeting = (this.tier.rocketStart && ADMIN_ROLES.GO_ADMIN_ROLES.includes(this.adminRole));
                this.viewScp = (this.tier.scp && (['tenant_admin', 'tenant_user'].includes(this.cpUserType)));
                let serviceToShow = this.getServiceToShowAfterLogin();
                // Based on which service to show, we need to set appropriate flags
                switch(serviceToShow) {
                    case "marvel":
                    case "rocketstart":
                        // After unification of go & meeting, isRmpExists should be false
                        this.isRmpExists = false;
                        // Flag isMarvelExists => If only Synappx Go license
                        this.isMarvelExists = (!tier.rmm && !tier.scp);
                        loginserviceName = 'Go';
                        this.token.setMarvelRMPService('marvel');
                        this.navigateToUrl('/workspace-list');
                        break;
                    case "rmm":
                        // Flag isRMMExists => If only Synappx Manage (RMM) license
                        this.isRMMExists = (!tier.marvel && !tier.rocketStart && !tier.scp);
                        loginserviceName = 'RMM';
                        this.redirectDefaultURL();
                        break;
                    case "scp":
                        // Flag onlyScpLicense => If only Synappx Cloud Print license
                        this.onlyScpLicense = (!tier.marvel && !tier.rocketStart && !tier.rmm);
                        loginserviceName = 'Cloud Print';
                        this.token.setMarvelRMPService('scp');
                        let jaCloudUrl = '/cloudprint/dashboard';
                        if (["tenant_user"].includes(this.cpUserType)) {
                           jaCloudUrl = '/cloudprint/jobs';
                        }
                        this.navigateToUrl(jaCloudUrl);
                        break;
                    default:
                        this.navigateToUrl('/service');
                        break;
                }
                // BA Details are available. Fire this event
                this.baDetailsAvailable.next(this.authn.loggedInUserDetails || scpUserInfo);
                this.logHandlerService.userName = this.authn.loggedInUserName;
                this.logHandlerService.userEmail = this.authn.userEmail;
                this.logHandlerService.tenantId = this.getConnectionName;
                this.logHandlerService.userRole = this.adminRole;
                this.logHandlerService.rmmRole = this.rmmAdminRole;
                this.logHandlerService.cpUserRole = this.cpUserRole;
                loginserviceName = (loginserviceName === 'RMM') ? 'Manage' : loginserviceName;
                this.serviceName = loginserviceName;
                if (loginserviceName !== "") {
                    this.logHandlerService.addAdminLog('Login', 'Login', 'MAA00001', 'Login', loginserviceName);
                }
            });
        });
    }

    /**
     * Find the service to show after login success
     * @returns The service (go/rmm/scp/none) name as string
     *
     * @description The result will contain service name (based on previously loggedin service or if only service license available). Otherwise it can also be empty to show service selection page.
     * In addition this method also sets the this.tenantTier property and this.isComboLicense flag
     * for use from other components/services.
     * The computation is based on the logged-in users previously used service and also admin access to licensed services. So if user is removed as admin from one service, or license expires, the value of serviceToShow will change.
     */
    private getServiceToShowAfterLogin() {
        const servicePreviouslyLoggedIn = this.loggedInUserDetails?.servicePreviouslyLoggedIn;
        // old names for list of services available (backward compatibility for old customers)
        const oldServiceList = [];
        // new names for list of services available
        const serviceList = [];
        if (this.viewGo) {
            oldServiceList.push('marvel');
            serviceList.push('go');
        }
        if (this.viewMeeting) {
            oldServiceList.push('rocketstart');
            if (!oldServiceList.includes('marvel')) {
                oldServiceList.push('marvel');
            }
            if (!serviceList.includes('go')) {
                serviceList.push('go');
            }
        }
        if (this.viewRmm) {
            oldServiceList.push('rmm');
            serviceList.push('rmm');
        }
        if (this.viewScp) {
            oldServiceList.push('scp');
            serviceList.push('scp');
        }
        // tenantTier will have values like: go, rmm, scp, goandrmm, goandscp, rmmandscp, goandrmmandscp
        // meeting is excluded after unification of products (meeting/go). So meetingandgo, meetingandrmm, meetingandgoandrmm are deprecated
        this.tenantTier = serviceList.join('and');
        this.isComboLicense = (serviceList.length > 1);
        let serviceToShow = '';
        // If the service logged into earlier is still available to loggedin user
        if (servicePreviouslyLoggedIn && oldServiceList.includes(servicePreviouslyLoggedIn)) {
            serviceToShow = servicePreviouslyLoggedIn;
        }
        if (serviceToShow == "" && !this.isComboLicense) {
            serviceToShow = oldServiceList[0];
        }
        return serviceToShow;
    }

    /**
     * Navigate to the correct url (previous url in case of F5 button, or default URL in case of fresh login)
     * @param defaultUrl default URL in case of fresh login
     * If this method is called after clicking F5 button, then navigate to previous url (retrieve from sessionStorage)
     * If this method is called after fresh login, then navigate to default url based on service (defaultUrl parameter)
     */

    private navigateToUrl(defaultUrl = "/roomlist") {
        let redirectToCalenderAccount = sessionStorage.getItem("navigate-to-gw-accounts-for-rooms") || "false_emailNotFound";
        const [redirect, targetEmail] = redirectToCalenderAccount.split("_");
        const navigateToDownload = localStorage.getItem("navigate-download");
        if (redirect == "true" && targetEmail == this.authn.userEmail) {
            this.router.navigate(["/admin/calendar-account"]);
            return;
        } else if (navigateToDownload) {
            this.router.navigate(["/downloads"]);
            localStorage.removeItem("navigate-download");
            return;
        } else {
            // use this.token.getCurrentUrl if it is valid value, otherwise use defaultUrl
            const tokenCurrentUrl = this.token.getCurrentUrl() || defaultUrl;
            // Separate currentUrl and route parameters
            // example: /spaces/spacedetail/6c41e1a2-c254-41ac-b949-407f29865c4d/device;isdisplayConfigured=true;param2=value2
            // currentUrl = /spaces/spacedetail/6c41e1a2-c254-41ac-b949-407f29865c4d/device
            // params = ["isdisplayConfigured=true", "param2=value2"]
            const [currentUrl, ...params] = tokenCurrentUrl.split(";");
            if (params && params.length > 0) {
                const routeParams: any = {};
                // split each key-value pair and assign to the object routeParams
                params.forEach((param: string) => {
                    const [key, value] = param.split("=");
                    routeParams[key] = value;
                });
                // console.log(`routeParams`, routeParams);
                // Navigate to the currentUrl with route params
                this.router.navigate([currentUrl, routeParams]);
            } else {
                this.router.navigate([currentUrl]);
            }
        }
    }

    logout() {
        this.authn.logout();
    }

    public calendarAccountLogout(): void {
        const logoutParams = {
            returnTo: `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${environment.AuthConfig.callbackURL}`,
            client_id: environment.AuthConfig.clientID
        }
        this.authn.auth0.logout(logoutParams);
        this.token.setLoggedInStatus(false);
        this.token.removeSession('currentUrl');
    }

    //Supporting languages code list
    getBrowserLanguage(): any {
        let browserLang = this.translate.getBrowserLang();
        let supportedLanguages = ['en'];
        if (environment.stackRegion == "EU") {
            supportedLanguages = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'sv'];
            // check only for norwegien
            const norLangCode = ['no', 'nb', 'nn'];
            if (norLangCode.includes(browserLang)) {
                browserLang = 'no';
            }
            // set browserlang to spanish if catalan or spanish is selected
            const spanishLangCode = ['ca', 'es'];
            if (spanishLangCode.includes(browserLang)) {
                browserLang = 'es';
            }
            browserLang = supportedLanguages.includes(browserLang) ? browserLang : 'en';
            this.translate.use(browserLang);
        } else {
            browserLang = 'en';
            this.translate.use(browserLang);
        }
        return browserLang;
    }

    redirectDefaultURL() {
        this.token.setMarvelRMPService('rmm');
        // to update parentGroupId (RMM_Manage)
        this.groupService.getGroupName(this.tenantInfo.domain, this.tenantInfo.customerName, this.tenantInfo.id).subscribe(data => {
            localStorage.setItem('parentGroupId', data.groupId);
            // to check if user is authorised to access respective url
            let validate = this.rmmValidate.checkValidateMethod(localStorage.getItem('redirectURL'))

            const localStorageCache = localStorage;
            const redirectURL = localStorageCache.getItem('redirectURL');
            const groupURL = localStorageCache.getItem('groupURL');
            const customDeviceURL = localStorageCache.getItem('customDeviceURL');
            const redirectUrl = redirectURL || groupURL || customDeviceURL;
            let defaultUrl = '/rmm/dashboard';
            this.tenantService.getTenantList(this.accessToken).subscribe(data => {
                if (data.multiTenant) {
                    defaultUrl = '/rmm/multiTenantDashboard';
                }
                if (localStorage.getItem("switchTenant") == "true") {
                    defaultUrl = 'rmm/dashboard';
                    // localStorage.setItem("switchTenant", "false");
                    // this.token.removeLocalStorage("switchTenant");
                }
                this.router.navigate([validate && redirectUrl ? redirectUrl : defaultUrl]);
                // this.token.removeLocalStorage("switchTenant");         
            }, error => {
                console.error('Error fetching tenant list:', error);
            });
            // this.router.navigate([validate && redirectUrl ? redirectUrl : defaultUrl]);
        })


    }

    registerCalendarAccount() {
        const options = {
            connection: this.authn.homeDomain,
            calendar_acc_info: {
                actionType: 'register',
                adminEmail: this.authn.userEmail,
                adminName: this.authn.loggedInUserName,
                appVersion: environment.version,
                adminRole: this.adminRole
            },
            connection_scope: "https://www.googleapis.com/auth/calendar.readonly",
            prompt: "login"
        };
        // we can check with prompt=login also
        this.authn.auth0.authorize(options);
    }


    handleLoginFailure(err) {
        this.authn.login();
        let loginRetries = Number(localStorage.getItem('loginRetries')) || 0;
        if (loginRetries > 3) {
            console.warn(`loginRetries: ${loginRetries}`);
            alert(`Error: ${err.error}. Check the console for further details.`);
        }
        loginRetries = loginRetries + 1;
        localStorage.setItem('loginRetries', loginRetries.toString());
    }

}
