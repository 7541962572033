import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalAppDataService } from '../../shared/services/local-app-data.service';
import { SspGenericService } from '../../shared/services/ssp/ssp-generic.service';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import camelCase from 'camelcase';
import { DateFormatService } from '../../shared/services/jadatetime-format.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TokenStorageService } from '../../shared/services/ssp/token-storage.service';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
import { ExportDialogComponent } from '../../shared/dialogs/export.dialog/export.dialog.component';
import { Helper } from '../../shared/services/ssp/helper';
import { LogHandlerService } from '../../shared/services/ssp/log-handler.service';
import { DateAdapter } from '@angular/material/core';


@Component({
    selector: 'lib-admin-log',
    templateUrl: './admin-log.component.html',
    styleUrls: ['./admin-log.component.scss'],
})
export class AdminLogComponent implements OnInit {
    public isMarvelExists: boolean;
    public isSCPExists: boolean;
    public isRmpExists: boolean;
    public isRMMExists: boolean;
    public isButtonClickable: boolean;
    private searchSubscription: any;
    error: any = { isError: false, errorMessage: '' };
    public tenantInfo: any;
    public accessToken: any;
    public tenantId: any;
    log_start_date: string;
    log_end_date: string;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSortHeader) matSortHeader: MatSortHeader;
    presentDate: Date;
    tenantDate: Date;
    startDateMinLimit: Date;
    minDate: Date;
    maxDate: Date;
    ESConditionValues: string[];
    userSelected = 0;
    lastClickStamp: string;
    totalCount = 0;
    dataSource: any;
    loadingStatus = true;
    public isSingleButton: boolean;
    filterForm: UntypedFormGroup;
    tabForm: UntypedFormGroup;
    isGo = false;
    isMeeting = false;
    isRMM = false;
    isSCP = false;
    userOrAgentList: any[];
    displayedColumns: string[] = ['log_generated_date', 'user_name', 'service_name', 'log_category', 'action'];
    public browserLang: string;
    dateQuery = '';
    protected exportDialogCurrent: any;
    availableReports = null;
    mainExportUrl: string;


    constructor(
        private appDataService: LocalAppDataService,
        private sspApiService: SspGenericService,
        private jadateTimeService: DateFormatService,
        private token: TokenStorageService,
        private datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>,
        private translate: TranslateService,
        private exportDialog: MatDialog,
        private http: HttpClient,
        private logHandlerService: LogHandlerService
    ) {
        this.initDates();
        this.browserLang = this.appDataService.getLanguage();
        this.dateAdapter.setLocale(this.browserLang);
    }

    ngOnInit(): void {
        this.appDataService.usingAuthInfo().subscribe((token) => {
            if (token) {
                this.accessToken = token;
            }
        });

        this.mainExportUrl = this.sspApiService.exportlogUrl;
        // For now, there is only on
        this.filterForm = new UntypedFormGroup({
            View: new UntypedFormControl(0),
        });

        this.isRMMExists = this.appDataService.ViewRMM;
        this.isMarvelExists = this.appDataService.ViewGo;
        this.isRmpExists = this.appDataService.ViewMeeting;
        this.isSCPExists = this.appDataService.ViewScp;

        // Set the ESConditionValues and flag values
        this.serviceSelection(this.isRmpExists, this.isMarvelExists, this.isRMMExists, this.isSCPExists);
        this.paginator.pageSize = this.token.getLogPageSize('adminLog') || 25;
        this.sort.active = 'log_generated_date';
        this.sort.direction = 'desc';
        this.searchLogData();
        this.userSelected = 0;
        this.getUniqueUsersList();
    }

    formatDate(dt) {
         return this.jadateTimeService.formatDateTimeLocale(dt);
    }

    selectedUser(event) {
        this.userSelected = event.value;
        this.paginator.pageIndex = 0;
        this.searchLogData();
    }

    /**
     * Get the string representation of date (in ISO 8601 format)
     */
    dateString(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    initDates() {
        const daysToReduce = 89; // Based on task SHPLT-1591 ninety days including today to be used for min date while selecting the dates
        this.tenantDate = this.appDataService.TenantInfoAtLogin.dateCreated;
        this.presentDate = new Date(Date.now());
        this.startDateMinLimit = new Date(this.presentDate.getFullYear(), this.presentDate.getMonth(), this.presentDate.getDate() - daysToReduce);
        this.minDate = (this.tenantDate && new Date(this.tenantDate) > this.startDateMinLimit) ? new Date(this.tenantDate) : this.startDateMinLimit;
        this.maxDate = this.presentDate;
        this.log_start_date = this.dateString(this.startDateMinLimit);
        this.log_end_date = this.dateString(this.presentDate);

    }

    getExport(startDate: string, endDate: string) {
        if (startDate === '' || endDate === '') {
            this.error = { isError: true, errorMessage: this.translate.instant('StartAndEndDateRequired') };
            return false;
        }
        startDate = this.jadateTimeService.parseDateLocale(startDate);
        endDate = this.jadateTimeService.parseDateLocale(endDate);
        if (endDate < startDate) {
            this.error = { isError: true, errorMessage: this.translate.instant('EndDateGreater') };
            return false;
        } else if (startDate <= endDate) {
            this.error = { isError: false, errorMessage: '' };
            this.getExportForDate(new Date(startDate), new Date(endDate));
        }
    }

    /**
 * Show the export data with the given endDate
 * @param endDate This is the end date for exporting raw data
 */
    getExportForDate(startDate: Date, endDate: Date) {
        const selectedDate = new Date(endDate);
        selectedDate.setDate(selectedDate.getDate() + 1);
        let timeZone = dayjs().format('Z');
        const toDate = encodeURIComponent(dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss') + timeZone);
        const fromDate = encodeURIComponent(dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss') + timeZone);
        timeZone = encodeURIComponent(timeZone);
        const langCode = (this.browserLang || 'en').toLowerCase().split('-').join('_');
        this.dateQuery = `from=${fromDate}&to=${toDate}&timezone=${timeZone}&lang=${langCode}`;
        this.exportDialogCurrent = this.exportDialog.open(ExportDialogComponent, {
            minHeight: '30%',
        });
        const datePrefix = this.datePipe.transform(new Date(), 'MM-dd-yy');
        this.availableReports = {
            synappx_audit_admin: `${datePrefix}_Synappx_Admin_Log.zip`,
        };
        const schemaIds = Object.keys(this.availableReports);
        const urlList = schemaIds.map((schema) => `${this.mainExportUrl}/${schema}?${this.dateQuery}`);
        if (this.exportDialogCurrent.componentInstance) {
            this.exportDialogCurrent.componentInstance.setReportCount(schemaIds.length);
        }
        this.downloadInMemory(urlList);
    }

    downloadInMemory(urlList) {
        // Take latest url
        const url = urlList.pop();
        let fileName = url.split('?')[0].split('/').pop();
        if (fileName in this.availableReports) {
            fileName = this.availableReports[fileName];
        }
        this.getBlob(url, fileName);
    }

    getBlob(url, fileName) {

        const httpOptions: {
            headers: HttpHeaders,
            observe: 'response',
            responseType: 'blob',
        } = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken,
                'accept': '*/*',
            }),
            observe: 'response',
            responseType: 'blob',
        };
        return this.http.get(url, httpOptions).subscribe((res) => {
            if (res.body) {
                Helper.downloadFile(fileName, res.body);
                this.logHandlerService.addAdminLog('Admin Log', 'Admin Log exported', 'MAA00034', 'Admin Log exported');
            } else {
                if (this.exportDialogCurrent.componentInstance) {
                    const errorMessage = this.translate.instant('ExportErrorNoData', { fileName: fileName });
                    this.exportDialogCurrent.componentInstance.AddError(errorMessage);
                }
            }
        }, (err) => {
            if (this.exportDialogCurrent.componentInstance) {
                const errorMessage = this.translate.instant('ExportErrorNoData', { fileName: fileName });
                this.exportDialogCurrent.componentInstance.AddError(errorMessage);
                this.exportDialogCurrent.componentInstance.downloadComplete(true);
            }
        },
            () => {
                if (this.exportDialogCurrent.componentInstance) {
                    this.exportDialogCurrent.componentInstance.downloadComplete(true);
                }
            });
    }


    getUniqueUsersList() {
        const schemaName = 'synappx_admin_v1';
        const tenantId = this.appDataService.ConnectionName;
        this.sspApiService.getUniqueUsersList(schemaName, tenantId, this.log_start_date, this.log_end_date, this.ESConditionValues).subscribe((data) => {
            this.userOrAgentList = data.aggregations.users_list.buckets.map(function (obj) { return obj.key; });
        });
    }

    onPagination(pageEvent: PageEvent) {
        this.token.setLogPageSize(pageEvent.pageSize, 'adminLog');
        this.searchLogData();
    }

    refreshPage() {
        this.paginator.pageIndex = 0;
        const sortState: Sort = { active: 'log_generated_date', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.getUniqueUsersList();
        this.searchLogData();
        this.sort.sortChange.emit();
        // sets the animation transition view state for the arrow's position and opacity.
        this.matSortHeader._setAnimationTransitionState({ toState: 'active' });
    }

    serviceSelection(isRmpExists, isMarvelExists, isRMMExists, isSCPExists) {
        this.isButtonClickable = false;
        this.isSingleButton = true;
        let selectedService = '';
        const availabeServices = [];
        const filteredServices = [];
        // This is Unified Menu (Go or Meeting). So update the ESConditions and isGo flag for common
        if (isRmpExists || isMarvelExists) {
            filteredServices.push("Go");
            filteredServices.push("Meeting");
            this.isGo = true;
        }
        if (isRmpExists) {
            availabeServices.push("meeting");
            this.isMeeting = true;
        }
        if (isMarvelExists) {
            availabeServices.push("go");
        }
        if (isRMMExists) {
            availabeServices.push("rmm");
            filteredServices.push("Manage");
            this.isRMM = true;
        }
        if (isSCPExists) {
            availabeServices.push("scp");
            filteredServices.push("Cloud Print");
            this.isSCP = true;
        }
        if (availabeServices.length > 1) {
            this.isButtonClickable = true;
            this.isSingleButton = false;
        }
        this.ESConditionValues = filteredServices;
        selectedService = availabeServices.join("and");
        return selectedService;
    }

    serviceSelected(service) {
        this.isMeeting = false;
        this.filterForm.get('View').enable();
        if (service === 'Go') {
            this.isGo = !this.isGo;
        } else if (service === 'Meeting') {
            this.isMeeting = !this.isMeeting;
        } else if (service === 'RMM') {
            this.isRMM = !this.isRMM;
        } else if (service === 'SCP') {
            this.isSCP = !this.isSCP;
        }
        const filteredServices = [];
        if (this.isGo) {
            filteredServices.push('Go', "Meeting");
        }
        // We can remove this condition as this was handled with Unified Menu implementation
        if (this.isMeeting) {
            filteredServices.push('Go');
        }
        if (this.isRMM) {
            filteredServices.push('Manage');
        }
        if (this.isSCP) {
            filteredServices.push('Cloud Print');
        }
        if (!this.isGo && !this.isMeeting && !this.isRMM && !this.isSCP) {
            this.filterForm.get('View').setValue(this.userSelected);
            this.filterForm.get('View').disable();
        }
        this.ESConditionValues = filteredServices;
        this.paginator.pageIndex = 0;
        this.searchLogData();
    }

    onSorting() {
        this.searchLogData();
    }

    searchLogData() {
        const schemaName = 'synappx_admin_v1';
        const ESConditionField = 'service_name';
        const timestamp = new Date(Date.now()).toISOString();
        const tenantId = this.appDataService.ConnectionName;
        this.lastClickStamp = timestamp;
        this.searchSubscription = this.sspApiService.searchLogData(schemaName, tenantId, this.log_start_date, this.log_end_date, this.sort.active, this.sort.direction, this.paginator.pageSize, this.paginator.pageIndex, ESConditionField, this.ESConditionValues, this.userSelected, 0).subscribe((data) => {
            if (this.lastClickStamp > timestamp) {
                // one more click happened. So ignore this search result
                return;
            } else {
                if (data.hits) {
                    this.totalCount = data.hits.total.value;
                    if (data.hits.hits) {
                        this.dataSource = data.hits.hits.map(item => {
                            // convert log_category value to match with l10n file id
                            let camelCaseId = camelCase(item._source.log_category.toLowerCase());
                            let log_category = this.translate.instant('value.' + camelCaseId);
                            //If value.XXXXXX cannot be gotten from l10n file show item._source.log_category value as it is
                            item._source.log_category = (log_category.includes('value.')) ? item._source.log_category : log_category;
                            //If action_id is there translate it otherwise show action as it is
                            let action_id = (item._source.action_id) ? this.translate.instant('message.' + item._source.action_id) : item._source.action;
                            //If user_mail and user_role exists, then add nameRole to the schema
                            let nameRole = item._source.user_mail ? item._source.user_mail + '.|' : '';
                            nameRole = item._source.user_role ? nameRole + item._source.user_role : '';
                            item._source['nameRole'] = nameRole.replace('Tenant Admin', 'Admin');
                            //If message.XXXXXX cannot be gotten from l10n file show item._source.action_id value as it is
                            item._source.action_id = (action_id.includes('message.')) ? item._source.action_id : action_id;
                            return item;
                        });
                    }
                    this.loadingStatus = false;
                }
            }
        });

    }

}
