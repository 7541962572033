import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { EmailAlertApi } from '../../shared/interfaces/emailAlertList';
import { GroupListApi } from '../../shared/interfaces/groupList';
import { GroupTreeApi } from '../../shared/interfaces/groupListTree';
import { environment } from '../../environments/environment';
import { CounterVariationApi } from '../../shared/interfaces/counterVariationDetail';
import DateUtilService from '../utils/date-util.service';
import { mergeMap } from 'rxjs/operators';

const headers = {
    'Ocp-Apim-Subscription-Key': environment.rmmHeader.Ocp_Apim_Subscription_Key, 
    'Ocp-Apim-Trace': 'true'
}

@Injectable({
    providedIn: 'root'
})
export class MygroupsService {

    groupOperationTitle: string
    groupOperationDailog: string
    parentGroupId: string;
    mode: string;
    refreshGroupTreeEvent: EventEmitter<string> = new EventEmitter();

    constructor(
        private _httpClient: HttpClient,
        private dateUtil: DateUtilService
    ) { }


    refreshDeviceListEvent: EventEmitter<string> = new EventEmitter();
    refreshDeviceTreeEvent: EventEmitter<string> = new EventEmitter();
    defaultGroupNameSubject: Subject<any> = new Subject();

    private dialogCloseSubject = new Subject<any>();
    dialogClosed$ = this.dialogCloseSubject.asObservable();

    notifyDialogClosed(data: any) {
        this.dialogCloseSubject.next(data); // Emit event with data
    }

    getGroupList(groupId: string, sort: string, order: string, startIndex: number, pageSize: number, filterkey: string, filtervalue: string): Observable<GroupListApi> {
        const data = {
            "groupId": groupId,
            "startIndex": startIndex,
            "count": pageSize,
            "totalFlag": true,
            "attribute": "assigned",
            "groupType": "Cluster",
            "filterList": [
                {
                    "key": filterkey,
                    "value": filtervalue
                }
            ],
            "orderBy": [
                {
                    "key": sort,
                    "order": order
                }
            ]
        }
        const url = environment.rmmGroupOperations.getGroupList;
        return this.getHttpPostData(url, data, headers);
    }
    getDeviceRegisterUrl(groupId: string) {
        const data = {
            "groupId": groupId
        }
        const url = environment.rmmTechnicalService.getRegisterDeviceUrl;
        return this.getHttpPostData(url, data, headers)
    }

    getGroupTree(): Observable<GroupTreeApi> {

        const data = {
            startIndex: 0,
            count: 100,
            userId: "testUser02",
            expand: "all"
        }
        const url = environment.rmmGroupOperations.getGroupTree
        return this.getHttpPostData(url, data, headers);
    }
    getHttpOptions() {
        const httpOptions = {
            headers: {
                'Ocp-Apim-Subscription-Key': environment.rmmHeader.Ocp_Apim_Subscription_Key, 
                'Ocp-Apim-Trace': 'true'
            },

        };
        return httpOptions
    }
    setdefaultGroup(defaultGroup) {
        this.defaultGroupNameSubject.next(defaultGroup)
    }
    getHttpPutData(url, data, headers) {
        return this._httpClient.put<any>(url, data, { headers });
    }
    getHttpPostData(url, data, headers) {
        return this._httpClient.post<any>(url, data, { headers });
    }
    getHttpGetData(url, data) {
        return this._httpClient.get<any>(url, data);
    }
    setDialogTitle(title) {
        this.groupOperationTitle = title
    }

    getDialogTitle() {
        return this.groupOperationTitle
    }
    setDialogAction(operation) {
        this.groupOperationDailog = operation
    }
    getDialogAction() {
        return this.groupOperationDailog
    }
    setParentNodeId(groupId) {
        this.parentGroupId = groupId
    }
    getParentNodeId() {
        return this.parentGroupId
    }
    getMailAlertList(groupId: string): Observable<EmailAlertApi> {
        const data = {
            "groupId": groupId,
        }
        const url = environment.rmmGroupOperations.getMailAlertList;
        return this.getHttpPostData(url, data, headers);
    }
    setMailAlertList(groupId: string, pageType: string, emailAlertList): Observable<any> {
        const data = {
            "groupId": groupId,
            "mode": pageType,
            "emailAlertList": emailAlertList
        }
        const url = environment.rmmGroupOperations.setMailAlertList;
        return this.getHttpPostData(url, data, headers);
    }

    getOperationResult(requestId: string, groupId:string): Observable<any> {
        const data = {
            "groupId": groupId,
            "requestId": requestId
        }
        const url = environment.rmmGroupOperations.getOperationResult;
        return this.getHttpPostData(url, data, headers);
    }

    emitGroupTreeRefresh(string) {
        this.refreshGroupTreeEvent.emit(string);
    }
    getGroupTreeRefresh() {
        return this.refreshGroupTreeEvent;
    }
    emitDeviceListRefresh(string) {
        this.refreshDeviceListEvent.emit(string);
    }
    getDeviceListRefresh() {
        return this.refreshDeviceListEvent;
    }

    emitDeviceTreeRefresh(groupId, groupName) {
        this.refreshDeviceTreeEvent.emit(groupId);
        this.refreshDeviceTreeEvent.emit(groupName);
    }
    getDeviceTreeRefresh() {
        return this.refreshDeviceTreeEvent;
    }

    editGroup(groupId: string, groupName: string, groupDescription: string, tagId:string): Observable<any> {
        const data = {
            groupId: groupId,
            groupName: groupName,
            description: groupDescription,
            tagId: tagId 
        }
        const url = environment.rmmGroupOperations.editGroup;
        return this.getHttpPostData(url, data, headers);
    }

   getTagList(groupId: string, sort: string, order: string, startIndex: number, pageSize: number, filterkey: string, filtervalue: string): Observable<any> {
        const data = {
            "groupId": groupId,
            "startIndex": startIndex,
            "count": pageSize,
            "totalFlag": true,
            "attribute": "managed",
            //"groupType": "Tag",
            "filterList": [
                {
                    "key": filterkey,
                    "value": filtervalue
                }
            ],
            "orderBy": [
                {
                    "key": sort,
                    "order": order
                }
            ]
        };
        const url = environment.rmmGroupOperations.getGroupList;
        return this.fetchData(url, data);
    }

    // recursive call to fetch all records
    private fetchData(url: string, data: any, accumulatedData: any[] = []): Observable<any> {
        return this.getHttpPostData(url, data, headers).pipe(
            mergeMap(response => {
                const newData = accumulatedData.concat(response.resultData); // Assuming API response has a 'data' property containing records
                const totalCount = response.totalCount; // Assuming API response contains total count of records
                const currentCount = newData.length;
                if (currentCount < totalCount) {
                    // Still more records to fetch
                    const nextPageIndex = data.startIndex + data.count;
                    return this.fetchData(url, { ...data, startIndex: nextPageIndex }, newData);
                } else {
                    // All records fetched
                    return of({ data: newData, response: response });
                }
            })
        );
    }

    createTag(groupId: string, groupName: string, description: string): Observable<any> {
        const data = {
            groupId: groupId,
            tagGroupName: groupName,
            description: description
        }
        const url = environment.rmmGroupOperations.createGroup;
        return this.getHttpPostData(url, data, headers);
    }
    
    startRemoveTag(tagIds: string[], groupId:string) {
        const data = {
            operation: 'delete',
            groupId: groupId,
            tagIds: tagIds
        }
        const url = environment.rmmGroupOperations.startRemoveGroup;
        return this.getHttpPostData(url, data, headers);
    }

    applyTagToDevice(srcGroupId: string, tagIds: string[], deviceIds: string[], isExclude: boolean, deviceTypeValue: string, unselectedTag): Observable<any> {

        const data = {
            groupId: srcGroupId,
            tagIds: tagIds,
            mode: "copy",
            deviceIds: deviceIds,
            isExclude: isExclude,
            deviceType: deviceTypeValue,
            untagIds: unselectedTag
        }
        const url = environment.rmmDeviceOperations.startPasteDevice;
        return this.getHttpPostData(url, data, headers);
    }

    getGroupName(groupName, customerName, id): Observable<any> {

        const data = {
            tenantName: groupName,
            customerName: customerName,
            id: id,
            timezone: this.dateUtil.getTimeZone()
        }

        const url = environment.rmmGroupOperations.groupInfo;
        return this.getHttpPostData(url, data, headers);
    }

    getSpecialCounter(groupId: string): Observable<any> {
        let httpOptions = this.getHttpOptions();
        const url = environment.rmmGroupOperations.getSpecialCounter + "?groupId=" + groupId
        return this.getHttpGetData(url, httpOptions);
    }
    setSpecialCounter(groupId: string, colorModeFlag: boolean, postCardOrA3Flag: boolean, utilityFlag: boolean): Observable<any> {
        const data = {
            "groupId": groupId,
            "colorModeFlag": colorModeFlag,
            "postCardOrA3Flag": postCardOrA3Flag,
            "utilityFlag": utilityFlag,
        }
        const url = environment.rmmGroupOperations.setSpecialCounter + "?groupId=" + groupId
        return this.getHttpPutData(url, data, headers);
    }
    getConnectWiseSettings(groupId: string, cwType: number): Observable<any> {
        let httpOptions = this.getHttpOptions();
        const url = environment.rmmConnectWiseOperations.getTicketRegistrationConf + "?groupId=" + groupId + "&cwType=" + cwType
        return this.getHttpGetData(url, httpOptions);
    }
    getCompanyServiceList(groupId: string, searchString: string, option: number) {
        let httpOptions = this.getHttpOptions();
        //encoding the searchstring while request
        const url = environment.rmmConnectWiseOperations.getCompnayListConf + "?groupId=" + groupId + "&filter=" + encodeURIComponent(searchString) + "&type=" + option;
        return this.getHttpGetData(url, httpOptions);
    }
    setConnectWiseSettings(groupId: string, cwType, basicSettingsObject, ticketSettingsObject, issueTicket): Observable<any> {

        let data: any

        data = {
            "groupId": groupId,
            "cwType": cwType,
            "basicSetting":
            {
                "companyId": basicSettingsObject.companyId,
                "publicKey": basicSettingsObject.publicKey,
                "changeKey": basicSettingsObject.changeKey,
                "privateKey": basicSettingsObject.privateKey,
                "selectedOriginId": basicSettingsObject.selectedOriginId
            },
            "ticketSetting":
            {
                "ticketFlag": ticketSettingsObject.ticketFlag,
                "companyId": ticketSettingsObject.companyId,
                "companyName": ticketSettingsObject.companyName,
                "customFieldFlag": ticketSettingsObject.customFieldFlag,
                "language": ticketSettingsObject.language,
                "timeZone": ticketSettingsObject.timeZone,
                "serviceBoardSettings": ticketSettingsObject.statusCondition
            }
        }


        const url = environment.rmmConnectWiseOperations.setTicketRegistrationConf + "?groupId=" + groupId + "&issueTicket=" + issueTicket
        return this.getHttpPostData(url, data, headers);
    }

    sortGroupList(TagList, TagList1) {
        let tagList2: any = [];
        TagList1.sort();
        TagList1.forEach(ele => {
            TagList.filter(ele0 => {
                if (ele0.tagName == ele || ele0.groupName == ele) {
                    tagList2.push(ele0)
                }
            })
        })
        // To remove duplicates in array
        tagList2 = [...new Set(tagList2)];

        return tagList2;
    }
    sendTestMail(groupId: string, toAddress: string, timeZone: string, language: string): Observable<EmailAlertApi> {
        const data = {
            groupId: groupId,
            toAddress: toAddress,
            timeZone: timeZone,
            language: language
        }
        const url = environment.rmmGroupOperations.sendTestMail;
        return this.getHttpPostData(url, data, headers);
    }

    deleteMail(groupId: string, emailAlertIds: any): Observable<EmailAlertApi> {
        const data = {
            groupId: groupId,
            emailAlertIds: emailAlertIds
        }
        const url = environment.rmmGroupOperations.deleteEmailSettingsMail;
        return this.getHttpPostData(url, data, headers);
    }

    //to get the tagnames from tagids
    getTagNames(tagIds: Array<string>, tagList) {
        let tagName = []
        if (typeof tagIds != 'undefined' && tagIds.length > 0) {
            tagIds.forEach((tag) => {
                let temp = this.getTagNameForId(tag, tagList)
                tagName.push(temp)
            })
        }
        // to remove empty string from array before converting to string
        tagName = tagName.filter((str) => str != '');
        return tagName
    }

    //to get the individual tagname
    getTagNameForId(id, tagList) {
        let tagName = ""
        tagList.forEach((data) => {
            if (data.tagId == id) {
                tagName = data.tagName;
            }
        })
        return tagName;
    }

    //to get the full tagnames to show in tooltip
    getFullTagNames(tagIds: Array<string>, tagList){
        return this.getTagNames(tagIds, tagList).join(', ')
    }

    //return the combined string with ... if it exceed more than 300
    truncateArrayStrings(array: string[]): string {
        const combinedString = array.join(", ");
        if (combinedString.length > 300) {
            return combinedString.substring(0, 300) + "...";
        }
        return combinedString;
    }

    processTagNames(tagName: string[], forTooltip: boolean = false): string {
        tagName = tagName.filter((str) => str !== '');
        const tagNamesString = tagName.join(', ');
        const isTruncated = tagNamesString.length > 300;
        if (forTooltip) {
          return isTruncated ? tagNamesString : '';
        } else {
          return isTruncated ? tagNamesString.substring(0, 300) + '...' : tagNamesString;
        }
    }
    
    //To filter the taglist based on tagname
    filterGroupOption(groupList, searchGroup) {
        const search = searchGroup.trim().toLowerCase();
        return groupList.filter((data) =>
            data.tagName.toLowerCase().includes(search)
        );
      }
    //csv file import api intigration for device onboarding
    importCsvDeviceList(groupId,formData: FormData): Observable<any> {
       const url = environment.rmmCustomDeviceType.importCsvDevicelist + "?groupId=" + groupId;
        return this.getHttpPostData(url, formData, headers);
    }    
}
